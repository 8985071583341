import React from "react";
import "../components/LandscapeCanvas/SplineCanvas.scss";
import Loader from "../assets/Loadernew.gif";

function LandscapeLoader() {
  return (
    <div className="main-landscape-loader">
      <div className="d-flex justify-content-between internal-loader">
        <div className="my-auto mx-auto">
          <img className="ms-5" src={Loader} alt="Landscape loader..." />
        </div>
      </div>
    </div>
  );
}

export default LandscapeLoader;
