import React, { useCallback, useEffect } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Controls,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import "./style.scss";

const LayoutFlow = ({ data }) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 150;
  const nodeHeight = 120;

  let position = { x: 0, y: 0 };
  const edgeType = "default";
  const getLayoutedElements = (nodes, edges, direction = "LR") => {
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "left";
      node.sourcePosition = "right";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x / 1.1 - nodeWidth / 2,
        y: nodeWithPosition.y / 1.1 - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  };
  //  eslint-disable-next-line
  const reactFlowInstance = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  getLayoutedElements(nodes, edges);
  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    [setEdges]
  );

  useEffect(() => {
    let nodesSet = data?.map((el, i) => {
      return {
        id: `${el.id}`,
        data: {
          label: (
            <div className="node-box">
              <div className="node-up">
                <div className="layerText" style={{ color: "black" }}>
                  {el?.layer}
                </div>
                <div className="nameText" style={{ color: "black" }} title={el?.name}>
                  {el?.name}
                </div>
              </div>
              <div className="node-down">
                <div className="descText" title={el?.desc}>
                  {el?.desc}
                </div>
              </div>
            </div>
          ),
        },
        type: el.id === 1 ? "input" : null,
        position,
      };
    });
    setNodes(nodesSet);

    let edgesSet = data
      ?.map((el, i) => {
        return (
          el.parent && {
            id: `e${el.parent}${el.id}`,
            source: `${el.parent}`,
            target: `${el.id}`,
            type: edgeType,
            // markerEnd: { type: "arrowclosed", width: "11", color: "#641E8C" },
            style: { strokeWidth: "2", stroke: "#641E8C" },
          }
        );
      })
      .filter(Boolean);
    setEdges(edgesSet);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    reactFlowInstance.fitView();
  }, [reactFlowInstance, nodes, edges]);

  return (
    <div className="layoutflow" style={{ height: "420px" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        preventScrolling={false}
        zoomOnScroll={true}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        onLoad={(instance) => setTimeout(() => instance.fitView(), 0)}
        //  panOnDrag={false}
      />
      <Controls />
    </div>
  );
};

function FlowWithProvider(props) {
  return (
    <ReactFlowProvider>
      <LayoutFlow {...props} />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
