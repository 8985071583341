import {
  baseUrl,
  hostUrl,
  sharePointUrl,
  authConfig,
  yammerUrl,
  wernerUrl,
} from "./Constants";

export function getEnvironment() {
  let key = "local";

  if (window.location.hostname.includes(".mosaic-dev.")) {
    key = "dev";
  } else if (window.location.hostname.includes(".mosaic-qa.")) {
    key = "qa";
  } else if (window.location.hostname.includes(".mosaic.")) {
    key = "prod";
  }

  return key;
}

export function getBaseUrl() {
  return baseUrl[getEnvironment()];
}

export function getHostUrl() {
  return hostUrl[getEnvironment()];
}

export function getSharePointUrl() {
  return sharePointUrl[getEnvironment()];
}

export function getYammerUrl() {
  return yammerUrl[getEnvironment()];
}

export function getWernerUrl() {
  return wernerUrl[getEnvironment()];
}

export function getAuthConfig() {
  return authConfig[getEnvironment()];
}
