import "../Favorite/Favorite.scss";
import React from "react";
import {
  Carousel,
  Stack,
  Card,
  Col,
  Row,
  Button,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import Loader from "../Loader/Loader";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useQuery } from "jsonapi-react";
import { getBaseUrl, getHostUrl } from "../../config/environment";
import { getData, patchData } from "../../services/apiService";
import { useMsal } from "@azure/msal-react";
import {
  signatureInvaliddMsg,
  sessionExpiredMsg,
} from "../../config/Constants";
import { chunk } from "lodash";
import { useClient } from "jsonapi-react";
import {
  FavAddModal,
  EditFavModal,
  DeleteFavModal,
  SubTableAddModal,
} from "../../utils/favoriteUtils";
import {
  linkIcon,
  folderIcon,
  cardArrow,
  threeDots,
} from "../../utils/iconUtils";
import { defaultUserConfig } from "../../config/Constants";
import ImageSvgs from "../ImageSvgs";

const GetFavoritesData = () => {
  const { instance } = useMsal();
  const { data, message } = useQuery([
    "user-favorites",
    { use_pagination: false, include: ["favorites"] },
  ]);

  if (message && message === signatureInvaliddMsg) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(sessionExpiredMsg)) {
      instance.logoutRedirect();
    }
  }

  return data;
};

const Favorite = ({ props, fullSize }) => {
  const navigate = useNavigate();
  let location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [addSubModalShow, setAddSubModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [linkData, setLinkData] = useState([]);
  const [editModal, setEditModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [folderLinkData, setFolderLinkData] = useState(null);
  const [stockData, setStockData] = useState();
  const [loading, setLoading] = useState(false);
  const client = useClient();

  const data = GetFavoritesData();

  const tableData = data;

  const userId = JSON.parse(sessionStorage.getItem("userData")).userId;
  const initRows = JSON.parse(sessionStorage.getItem("userConfig"))?.row;
  const userConfigData = JSON.parse(sessionStorage.getItem("userConfig"));

  if (!initRows) {
    async function setData() {
      const data = {
        data: {
          type: "user",
          attributes: {
            configuration: {
              row: userConfigData?.row
                ? userConfigData?.row
                : defaultUserConfig.configuration.row,
              homeConfig: userConfigData?.homeConfig
                ? userConfigData?.homeConfig
                : defaultUserConfig?.configuration?.homeConfig,
              lunchData: userConfigData?.lunchData
                ? userConfigData?.lunchData
                : defaultUserConfig?.configuration.lunchData,
              sacLinks: userConfigData?.sacLinks
                ? userConfigData?.sacLinks
                : defaultUserConfig?.configuration.sacLinks,
            },
          },
          id: userId.toString(),
        },
      };
      try {
        // eslint-disable-next-line
        const res = await patchData(
          `${getHostUrl()}user/config`,
          data,
          "idToken"
        );
        sessionStorage.setItem(
          "userConfig",
          JSON.stringify(res?.data.data.attributes.configuration)
        );
      } catch (err) {
        return err;
      }
    }
    setData();
  }

  const initialRows = initRows ? initRows : "1";

  const [active, setActive] = useState(initialRows);
  const [rows, setRows] = useState(initialRows);

  const [rowsChange, setRowsChange] = useState(false);

  const chunkdata = chunk(data, fullSize ? 6 : 3);
  const chunkdata2 = chunk(data, fullSize ? 12 : 6);

  useEffect(() => {
    (async () => {
      let counter = data?.length;
      data?.forEach((favorite) => {
        if (favorite.order !== counter) {
          // eslint-disable-next-line
          const resData = client.mutate(["user-favorites", favorite.id], {
            is_folder: favorite["is-folder"],
            order: counter,
          });
        }
        counter--;
      });
    })();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setActive(initialRows);

    const param = `${getBaseUrl()}/configurations/home/stock-images.json`;
    async function getResponse() {
      const res = await getData(param);
      setStockData(chunk(res.data.images, 6));
    }
    getResponse();
    // eslint-disable-next-line
  }, [initialRows]);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  async function SaveRows() {
    const data = {
      data: {
        type: "user",
        attributes: {
          configuration: {
            row: rows.toString()
              ? rows.toString()
              : defaultUserConfig?.configuration?.row,
            homeConfig: userConfigData?.homeConfig
              ? userConfigData?.homeConfig
              : defaultUserConfig?.configuration?.homeConfig,
            lunchData: userConfigData?.lunchData
              ? userConfigData?.lunchData
              : defaultUserConfig?.configuration?.lunchData,
            sacLinks: userConfigData?.sacLinks
              ? userConfigData?.sacLinks
              : defaultUserConfig?.configuration.sacLinks,
          },
        },
        id: userId.toString(),
      },
    };

    sessionStorage.setItem(
      "userConfig",
      JSON.stringify(data.data.attributes.configuration)
    );

    try {
      setLoading(true);
      // eslint-disable-next-line
      const res = await patchData(
        `${getHostUrl()}user/config`,
        data,
        "idToken"
      );
      setLoading(false);
      setRowsChange(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  }

  async function SetRows(num) {
    await setActive(num);
    await setRows(num);

    if (num !== initialRows) {
      setRowsChange(true);
    } else if (num === initialRows) {
      setRowsChange(false);
    }
  }

  function FolderLinksModal(props) {
    let linksList = props.data.sort(
      (a, b) => parseFloat(b.order) - parseFloat(a.order)
    );

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="folder-modal"
          >
            {"Links :"}
            <button
              id="common-favbutton"
              className="modal-fav-add"
              onClick={() => {
                setAddSubModalShow(true);
                props.onHide();
              }}
            >
              Add Link
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {linksList.length === 0 ? (
            <p className="empty-msg">
              Empty folder. Add links to view them here.
            </p>
          ) : (
            <Row xs={1} md={3} className="g-4">
              {linksList.map((record, id) => (
                <Col key={id}>
                  <div className="link-popup fav-grid">
                    <Card key={record.id} className="card-list">
                      <div className="img-hover-zoom">
                        {record["favorite-image"] === null ? (
                          <Card.Img
                            variant="bottom"
                            src={`${getBaseUrl()}/images/default-images/default-fav.jpg`}
                            alt="Demo image"
                          />
                        ) : (
                          <Card.Img
                            variant="bottom"
                            src={`${getBaseUrl()}${record["favorite-image"]}`}
                            alt="Demo image"
                          />
                        )}
                      </div>
                      <div className="fav-card-dots">
                        <Dropdown className="fav-dropdown">
                          <Dropdown.Toggle>{threeDots}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setEditModalShow(true);
                                  setEditModal(record);
                                  props.onHide();
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                            </>
                            <Dropdown.Item
                              onClick={() => {
                                setDeleteModalShow(true);
                                setDeleteModal(record);
                                props.onHide();
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Card.ImgOverlay
                        onClick={() =>
                          window.open(record["favorite-redirect-url"], "_blank")
                        }
                      >
                        <Card.Body>
                          <Card.Title
                            data-toggle="tooltip"
                            data-placement="top"
                            title={record["favorite-name"]}
                          >
                            {linkIcon} &nbsp;{record["favorite-name"]}
                          </Card.Title>
                          <div className="fav-card-arrow">{cardArrow}</div>
                        </Card.Body>
                      </Card.ImgOverlay>
                    </Card>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  if (data !== null) {
    return (
      <div className="w-100">
        <div id="favorites-section" className="favorite mt-1 mb-3">
          <div className="fav-navbar">
            <div className="news-header d-flex flex-column  mx-auto my-2">
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <h4 className="sec-title fw-bold mt-2">{props.title}</h4>
                </div>
                <div className="d-flex flex-row">
                  {rowsChange ? (
                    <Button
                      className="app-secondary-btn"
                      onClick={() => {
                        SaveRows(rows);
                      }}
                    >
                      {loading ? "Saving..." : "Save"}
                    </Button>
                  ) : null}
                  {props["display-show-all"] ? (
                    <div>
                      <Button
                        className="ms-2 app-primary-btn"
                        onClick={() => setAddModalShow(true)}
                      >
                        Add New +
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex">
                  <h5 className="my-1">Select number of rows:</h5>
                  <ButtonGroup className="me-2 ms-2" aria-label="First group">
                    <Button
                      className={
                        active === "1" ? "fav-row-btn activeBtn" : "fav-row-btn"
                      }
                      onClick={() => {
                        SetRows("1");
                      }}
                    >
                      1
                    </Button>
                    <Button
                      className={
                        active === "2"
                          ? "fav-row-btn activeBtn mx-2"
                          : "fav-row-btn mx-2"
                      }
                      onClick={() => {
                        SetRows("2");
                      }}
                    >
                      2
                    </Button>
                  </ButtonGroup>
                </div>

                {props["display-show-all"] ? (
                  <div>
                    <Button
                      className="app-tertiary-btn"
                      onClick={() => navigate("/favorites")}
                    >
                      {props["show-all-text"]}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {data ? (
            <div className="favorite-carousel" id="fav-carousel">
              {data?.length > 0 ? (
                <>
                  {rows === "1" ? (
                    <Carousel interval={null} wrap={false} controls={false}>
                      {chunkdata.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100  align-items-center"
                            gap={2}
                          >
                            {chunkdata[i].map((record, j) => (
                              <Card
                                key={record.id}
                                className={
                                  fullSize ? "" : "favorite-small-carousel"
                                }
                              >
                                <div className="img-hover-zoom">
                                  {record["favorite-image"] === null ? (
                                    <a
                                      href={record["favorite-redirect-url"]}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Card.Img
                                        variant="bottom"
                                        src={`${getBaseUrl()}/images/default-images/default-fav.jpg`}
                                        alt="Demo image"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      href={record["favorite-redirect-url"]}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Card.Img
                                        variant="bottom"
                                        src={`${getBaseUrl()}${
                                          record["favorite-image"]
                                        }`}
                                        alt="Demo image"
                                      />
                                    </a>
                                  )}
                                </div>

                                <div className="fav-card-dots">
                                  <Dropdown className="fav-dropdown">
                                    <Dropdown.Toggle>
                                      {threeDots}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setEditModalShow(true);
                                          setEditModal(record);
                                        }}
                                      >
                                        {" "}
                                        Edit{" "}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setDeleteModalShow(true);
                                          setDeleteModal(record);
                                        }}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>

                                {record["is-folder"] ? (
                                  <Card.ImgOverlay>
                                    <Card.Body>
                                      <div
                                        className="fav-title"
                                        onClick={() => {
                                          setModalShow(true);
                                          setLinkData(record.favorites);
                                          setFolderLinkData(record);
                                        }}
                                        style={{ height: "7.5rem" }}
                                      >
                                        <Card.Title
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={record["favorite-name"]}
                                        >
                                          {folderIcon}&nbsp;
                                          {record["favorite-name"]}
                                        </Card.Title>
                                      </div>
                                      <>
                                        <div
                                          className="fav-card-arrow"
                                          onClick={() => {
                                            setModalShow(true);
                                            setLinkData(record.favorites);
                                          }}
                                        >
                                          {cardArrow}
                                        </div>
                                      </>
                                    </Card.Body>
                                  </Card.ImgOverlay>
                                ) : (
                                  <Card.ImgOverlay
                                    onClick={() =>
                                      window.open(
                                        record["favorite-redirect-url"],
                                        "_blank"
                                      )
                                    }
                                  >
                                    <Card.Body>
                                      <div className="fav-title">
                                        <Card.Title
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={record["favorite-name"]}
                                        >
                                          {linkIcon}&nbsp;
                                          {record["favorite-name"]}
                                        </Card.Title>
                                      </div>
                                      <div className="fav-card-arrow">
                                        {cardArrow}
                                      </div>
                                    </Card.Body>
                                  </Card.ImgOverlay>
                                )}
                              </Card>
                            ))}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <Carousel interval={null} wrap={false} controls={false}>
                      {chunkdata2.map((record, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <Row xs={1} md={fullSize ? 6 : 3} className="g-2">
                              {chunkdata2[i].map((record, id) => {
                                return (
                                  <Col key={id}>
                                    <div className="link-popup fav-grid">
                                      <Card key={record.id} className="w-100">
                                        <div className="img-hover-zoom">
                                          {record["favorite-image"] === null ? (
                                            <a
                                              href={
                                                record["favorite-redirect-url"]
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <Card.Img
                                                variant="bottom"
                                                src={`${getBaseUrl()}/images/default-images/default-fav.jpg`}
                                                alt="Demo image"
                                              />
                                            </a>
                                          ) : (
                                            <a
                                              href={
                                                record["favorite-redirect-url"]
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <Card.Img
                                                variant="bottom"
                                                src={`${getBaseUrl()}${
                                                  record["favorite-image"]
                                                }`}
                                                alt="Demo image"
                                              />
                                            </a>
                                          )}
                                        </div>

                                        <div className="fav-card-dots">
                                          <Dropdown className="fav-dropdown">
                                            <Dropdown.Toggle>
                                              {threeDots}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setEditModalShow(true);
                                                  setEditModal(record);
                                                }}
                                              >
                                                {" "}
                                                Edit{" "}
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setDeleteModalShow(true);
                                                  setDeleteModal(record);
                                                }}
                                              >
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>

                                        {record["is-folder"] ? (
                                          <Card.ImgOverlay>
                                            <Card.Body>
                                              <div
                                                className="fav-title"
                                                onClick={() => {
                                                  setModalShow(true);
                                                  setLinkData(record.favorites);
                                                  setFolderLinkData(record);
                                                }}
                                                style={{ height: "7.5rem" }}
                                              >
                                                <Card.Title
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={
                                                    record["favorite-name"]
                                                  }
                                                >
                                                  {folderIcon}&nbsp;
                                                  {record["favorite-name"]}
                                                </Card.Title>
                                              </div>
                                              <>
                                                <div
                                                  className="fav-card-arrow"
                                                  onClick={() => {
                                                    setModalShow(true);
                                                    setLinkData(
                                                      record.favorites
                                                    );
                                                  }}
                                                >
                                                  {cardArrow}
                                                </div>
                                              </>
                                            </Card.Body>
                                          </Card.ImgOverlay>
                                        ) : (
                                          <Card.ImgOverlay
                                            onClick={() =>
                                              window.open(
                                                record["favorite-redirect-url"],
                                                "_blank"
                                              )
                                            }
                                          >
                                            <Card.Body>
                                              <div className="fav-title">
                                                <Card.Title
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={
                                                    record["favorite-name"]
                                                  }
                                                >
                                                  {linkIcon}&nbsp;
                                                  {record["favorite-name"]}
                                                </Card.Title>
                                              </div>
                                              <div className="fav-card-arrow">
                                                {cardArrow}
                                              </div>
                                            </Card.Body>
                                          </Card.ImgOverlay>
                                        )}
                                      </Card>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  )}
                </>
              ) : (
                <div
                  className="d-flex w-100 border bg-white"
                  style={{ height: "24rem" }}
                >
                  <div className="mx-auto my-auto">
                    <ImageSvgs name="no_trainings" />
                    <p className="mb-0 mx-auto fw-bold">
                      There are no favorites available
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ maxWidth: "100%", height: "24rem" }}>
              <Loader />
            </div>
          )}
          {modalShow === true ? (
            <div id="fav-modal">
              <FolderLinksModal
                data={linkData}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          ) : (
            ""
          )}
          {addModalShow === true ? (
            <FavAddModal
              data={{
                stockData: stockData,
                favData: data,
                orderNumber: data.length,
              }}
              show={addModalShow}
              onHide={() => setAddModalShow(false)}
            />
          ) : (
            ""
          )}
          {addSubModalShow === true ? (
            <SubTableAddModal
              data={{ stockData: stockData, favData: folderLinkData }}
              show={addSubModalShow}
              onHide={() => setAddSubModalShow(false)}
            />
          ) : (
            ""
          )}
          {editModalShow === true ? (
            <div id="edit-modal">
              <EditFavModal
                data={{ editModal, stockData, tableData }}
                show={editModalShow}
                onHide={() => setEditModalShow(false)}
              />
            </div>
          ) : (
            ""
          )}
          {deleteModalShow === true ? (
            <div className="delete-modal">
              <DeleteFavModal
                data={deleteModal}
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
};
export default Favorite;
