import { React, useEffect, useState } from "react";
import { getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { getBaseUrl } from "../../config/environment";
import { EmployeeShowModal } from "../CommonComponents/EmployeeShowModal";
import { Container, Row, Col } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { Button } from "react-bootstrap";
import ResponsivePagination from "react-responsive-pagination";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { MultipleSelectDropdown } from "../CommonComponents/MultipleSelect";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CommunityDetailsPage from "./CommunityDetailsPage";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import Loader from "../Loader/Loader";
import "./CommunityUserPages.scss";

export default function CommunitiesUpdatedUserPage(props) {
  const itemsPerPage = 20;
  const orgList = props.orgList;
  const initialCommnityData = props.data;
  const url = new URL(window.location.href);
  const [communityData, setCommunityData] = useState(initialCommnityData);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesVal, setTotalPagesVal] = useState();
  const [show, setShow] = useState(false);
  const [activeComId, setActiveComId] = useState();
  const [activeComData, setActiveComData] = useState();
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [nameQuery, setNameQuery] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [orgQuery, setOrgQuery] = useState([]);
  const [finalOrgQuery, setFinalOrgQuery] = useState([]);
  const [activeButton, setActiveButton] = useState("Expertise Communities");
  const [dropdownShow, setDropdownShow] = useState(false);
  const [channel, setChannel] = useState([]);
  const [currentCommunityTag, setCurrentCommunityTag] = useState([
    { name: activeButton, id: 2 },
  ]);
  const [communityCategory, setCommunityCategory] = useState([]);
  const [finalCommunityTag, setFinalCommnityTag] = useState([]);
  const [finalCommunityType, setFinalCommnityType] = useState([]);
  const [finalCommunityChannel, setFinalCommunityChannel] = useState([]);
  // eslint-disable-next-line
  const [level, setLevel] = useState(url.searchParams.get("page"));

  const debouncedGetResponse = debounce(getResponse, 500);

  useEffect(() => {
    debouncedGetResponse();
    return debouncedGetResponse.cancel;
    // eslint-disable-next-line
  }, [
    currentPage,
    finalOrgQuery,
    activeButton,
    nameQuery,
    finalCommunityType,
    finalCommunityTag,
    finalCommunityChannel,
  ]);

  useEffect(() => {
    setCurrentPage(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromURL = urlParams.get("page");
    if (idFromURL && idFromURL.startsWith("communities")) {
      const id = idFromURL.substring("communities".length);
      if (id?.length === 0) {
        setActiveComId(null);
        setShow(false);
      } else {
        setActiveComId(id);
        setShow(true);
      }
    }
  }, []);

  const options = [
    {
      name: "Applications",
      id: "1",
    },
    {
      name: "Processes",
      id: "2",
    },
    {
      name: "Tools",
      id: "3",
    },
    {
      name: "Miscellaneous",
      id: "1",
    },
  ];

  const communityTagOptions = [
    {
      name: "Expertise Communities",
      id: 1,
    },
    { name: "ITM Communities", id: 2 },
  ];

  const channels = [
    {
      name: "Teams",
      id: 1,
    },
    {
      name: "SharePoint",
      id: 2,
    },
    {
      name: "Viva Engage",
      id: 3,
    },
    {
      name: "Others",
      id: 4,
    },
  ];

  async function getResponse() {
    setShowLoader(true);

    let orgId = finalOrgQuery?.[0]?.id;
    let queryParams = new URLSearchParams();
    if (
      finalCommunityTag?.length > 0 ||
      (activeButton?.length > 0 && activeButton === "Expertise Communities") ||
      currentCommunityTag?.length > 0
    ) {
      if (
        (activeButton?.length > 0 &&
          activeButton === "Expertise Communities") ||
        activeButton === "ITM Communities"
      ) {
        queryParams.append("tags", activeButton);
      } else if (finalCommunityTag?.length > 0) {
        queryParams.append("tags", finalCommunityTag[0].name);
      }
    }

    if (activeButton?.length > 0 && activeButton === "All Communities") {
      queryParams = new URLSearchParams();
    }

    if (finalCommunityChannel?.length > 0) {
      queryParams.append(
        "channel",
        finalCommunityChannel[0].name === "SharePoint"
          ? "Share point"
          : finalCommunityChannel[0].name
      );
    }
    if (nameQuery?.length > 0) {
      queryParams.append("name", nameQuery);
    }
    if (orgId) {
      queryParams.append("org", orgId);
    }
    if (finalCommunityType?.length > 0) {
      queryParams.append(
        "category",
        finalCommunityType[0].name === "Applications"
          ? "Application"
          : finalCommunityType?.[0]?.name === "Processes"
          ? "Process"
          : finalCommunityType?.[0]?.name
      );
    }

    queryParams.append("page", currentPage.toString());
    queryParams.append("per_page", itemsPerPage.toString());

    let communityData = `${getHostUrl()}community/search?${queryParams.toString()}`;

    const communityRes = await getData(communityData);
    setShowLoader(false);

    try {
      if (communityRes) {
        setCommunityData(communityRes.data.data);
        setTotalPagesVal(communityRes.data.meta["page-count"]);
      }
    } catch (error) {
      setCommunityData([]);
    }
  }

  const handleCommunity = (id) => {
    setActiveComId(id);
    setLevel(`communities${id}`);
    setShow(true);
  };

  const handleEmployeeModal = (data) => {
    const updatedData = { data: data };
    setActiveComData(updatedData);
    setShowEmployeeModal(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseModal = () => {
    setShowEmployeeModal(false);
  };

  const handleButtonClick = (buttonId) => {
    setCurrentPage("1");
    if (buttonId === "Expertise Communities") {
      setActiveButton(buttonId);
      setCurrentCommunityTag([{ name: buttonId, id: 1 }]);
    } else if (buttonId === "ITM Communities") {
      setActiveButton(buttonId);
      setCurrentCommunityTag([{ name: buttonId, id: 2 }]);
    } else {
      setActiveButton(buttonId);
      setCurrentCommunityTag([]);
    }
  };

  const handleInputChange = (e) => {
    setCurrentPage("1");
    if (e.target.value) {
      setNameQuery(e.target.value);
    } else {
      setNameQuery("");
    }
  };

  const handleOrgChange = (value) => {
    if (value?.length) {
      setOrgQuery(value);
    } else {
      setOrgQuery([]);
    }
  };

  const handleChannelChange = (value) => {
    setChannel(value);
  };

  const handleCategoryChange = (value) => {
    setCommunityCategory(value);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDropdown = () => {
    setDropdownShow(true);
  };

  const handleCommunityTag = (value) => {
    setCurrentPage("1");
    setCurrentCommunityTag(value);
  };

  const handleFiltersApply = () => {
    if (
      orgQuery?.length > 0 ||
      currentCommunityTag?.length > 0 ||
      channel?.length > 0 ||
      communityCategory?.length > 0 ||
      channel?.length > 0
    ) {
      if (currentCommunityTag?.length > 0) {
        setActiveButton(currentCommunityTag?.[0]?.name);
      }
      setCurrentPage("1");
      setFinalOrgQuery(orgQuery);
      setFinalCommnityTag(currentCommunityTag);
      setFinalCommnityType(communityCategory);
      setFinalCommunityChannel(channel);
      setDropdownShow(false);
    } else {
      setFinalOrgQuery([]);
      setChannel([]);
      setCurrentCommunityTag([]);
      setCommunityCategory([]);
      setDropdownShow(false);
      setFinalCommnityType([]);
      setFinalCommnityTag([]);
      setFinalCommunityChannel([]);
      // setActiveButton("All Communities");
    }
  };

  const handleResetFilter = () => {
    setOrgQuery([]);
    setChannel([]);
    setCurrentCommunityTag([]);
    setCommunityCategory([]);
  };

  const renderCommunities = (communityDetails) => {
    return (
      <>
        <div className="grid-container">
          {communityDetails?.length > 0 ? (
            <div className=" w-100 d-flex flex-wrap flex-row">
              {communityDetails?.map((value) => {
                return (
                  <div key={value.id} className="community-cards grid-item">
                    <div style={{ minHeight: "8rem" }}>
                      <Link
                        className="community-name"
                        style={{ textDecoration: "none" }}
                        to={`/landscape/communities?page=communities${value.id}`}
                        state={{ prevPath: window.location.href }}
                      >
                        <div
                          onClick={() => {
                            handleCommunity(value.id);
                          }}
                        >
                          <div style={{ minHeight: "4.3rem" }}>
                            <Col className="d-flex flex-row justify-content-between">
                              <div className="w-75">
                                <div className=" community-title">
                                  {value.attributes["community-name"]}
                                </div>
                              </div>
                              <div>
                                <span className="px-1 d-flex flex-row">
                                  {value.attributes["community-image"] ? (
                                    <img
                                      className="my-auto"
                                      src={`${getBaseUrl()}${
                                        value.attributes["community-image"]
                                      }`}
                                      style={{
                                        height: "2.6rem",
                                        width: "2.6rem",
                                        border: "1.5px solid black",
                                        borderRadius: "7rem",
                                        objectFit: "contain",
                                      }}
                                      alt="User Img"
                                    />
                                  ) : (
                                    <span className="my-auto">
                                      <span>
                                        <ImageSvgs name="default_img" />
                                      </span>
                                    </span>
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col className="pt-1 pb-1">
                              <div className="community-type">
                                <span>
                                  {value?.attributes?.tags ===
                                  "Expertise Communities"
                                    ? "Expertise"
                                    : value?.attributes?.tags ===
                                      "ITM Communities"
                                    ? "ITM"
                                    : ""}
                                </span>
                              </div>
                            </Col>
                          </div>
                          <Col className="pt-1 pb-1">
                            <div className="community-desc">
                              {value.attributes["community-desc"]}
                            </div>
                          </Col>
                        </div>
                      </Link>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div>
                        {value.attributes?.["members-count"] > 0 ? (
                          <div
                            className="members-count"
                            onClick={() => {
                              handleEmployeeModal(value);
                            }}
                          >
                            {value?.attributes["community-type"] === 0 ? (
                              <>
                                {value?.attributes["members-count"] + " "}
                                {value?.attributes["members-count"] === 1
                                  ? "member"
                                  : "members"}
                              </>
                            ) : (
                              <>
                                {value.relationships?.members?.data?.length +
                                  " "}
                                {value.relationships?.members?.data?.length ===
                                1
                                  ? "member"
                                  : "members"}
                              </>
                            )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="d-flex flex-row">
                        <>
                          {value?.relationships?.["communities-process"]?.data
                            ?.length > 0 && (
                            <div className="mx-1 me-1">
                              <OverlayTrigger
                                placement="left"
                                bsPrefix="tooltip-style"
                                overlay={<Tooltip>Processes</Tooltip>}
                              >
                                <span>
                                  <ImageSvgs name="process_indicator" />
                                </span>
                              </OverlayTrigger>
                            </div>
                          )}

                          {value?.relationships?.tools.data?.length > 0 && (
                            <div className="mx-1 me-1">
                              <OverlayTrigger
                                placement="left"
                                bsPrefix="tooltip-style"
                                overlay={<Tooltip>Tools</Tooltip>}
                              >
                                <span>
                                  <ImageSvgs name="tool_indicator" />
                                </span>
                              </OverlayTrigger>
                            </div>
                          )}

                          {value?.relationships?.apps?.data?.length > 0 && (
                            <div className="mx-1 me-1">
                              <OverlayTrigger
                                placement="left"
                                bsPrefix="tooltip-style"
                                overlay={<Tooltip>Applications</Tooltip>}
                              >
                                <span>
                                  <ImageSvgs name="app_indicator" />
                                </span>
                              </OverlayTrigger>
                            </div>
                          )}

                          {value?.relationships["communities-process"]?.data
                            ?.length === 0 &&
                            value?.relationships?.tools?.data?.length === 0 &&
                            value?.relationships?.apps?.data?.length === 0 && (
                              <div className="mx-1 me-1">
                                <OverlayTrigger
                                  placement="left"
                                  bsPrefix="tooltip-style"
                                  overlay={<Tooltip>Miscellaneous</Tooltip>}
                                >
                                  <span>
                                    <ImageSvgs name="miss_indicator" />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            )}
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center "
              style={{ paddingTop: "5rem" }}
            >
              <div>
                <ImageSvgs name="no_data" />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="main-community-page">
      <div className="community-content">
        <Container fluid className="mx-0 me-0 main-community-container">
          <Row xs={12} xl={12} className="d-flex">
            <Col xs={6}>
              {" "}
              <ButtonGroup className="my-2" aria-label="First group">
                <Button
                  className={
                    activeButton === "All Communities"
                      ? "form-select-btn form-select-activeBtn"
                      : "form-select-btn"
                  }
                  style={{ minWidth: "13rem" }}
                  onClick={() => handleButtonClick("All Communities")}
                >
                  All Communities
                </Button>
                <Button
                  className={
                    activeButton === "Expertise Communities"
                      ? "form-select-btn form-select-activeBtn"
                      : "form-select-btn"
                  }
                  style={{ minWidth: "13rem" }}
                  onClick={() => handleButtonClick("Expertise Communities")}
                >
                  Expertise Communities
                </Button>
                <Button
                  className={
                    activeButton === "ITM Communities"
                      ? "form-select-btn form-select-activeBtn"
                      : "form-select-btn"
                  }
                  style={{ minWidth: "13rem" }}
                  onClick={() => handleButtonClick("ITM Communities")}
                >
                  ITM Communities
                </Button>
              </ButtonGroup>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <Col xs={5}>
                <div className="app-primary-input-field pt-1">
                  <input
                    type="search"
                    id={"message"}
                    value={nameQuery}
                    placeholder="Search Community..."
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={1} className="d-flex justify-content-end">
                <Dropdown
                  show={dropdownShow}
                  onSelect={handleDropdown}
                  className="custom-dropdown-main"
                >
                  <Dropdown.Toggle
                    onClick={() => setDropdownShow(true)}
                    id="dropdown-basic"
                    className="dropdown-btn pe-0"
                  >
                    <ImageSvgs name="filterLogo" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100 custom-dropdown">
                    <div className="dropdown-title"> Advanced Filters</div>
                    <div className="pt-2 pb-2">
                      <div className="pb-1 pt-1">Org Code</div>
                      <div className="app-primary-dropdown">
                        <MultipleSelectDropdown
                          multi={false}
                          color="#8A00E5"
                          placeholder="Search org code"
                          className="dropdown-select mb-2"
                          options={
                            orgList?.length > 0 &&
                            [...orgList]
                              .sort(
                                (a, b) =>
                                  a.attributes["org-title"].split(" ").length -
                                  1 -
                                  (b.attributes["org-title"].split(" ").length -
                                    1)
                              )
                              ?.map((org) => ({
                                type: "orgs",
                                name: org.attributes["org-title"],
                                id: org.id.toString(),
                              }))
                          }
                          onChange={handleOrgChange}
                          values={orgQuery}
                        />
                      </div>
                    </div>

                    <div className="pt-2 pb-2">
                      <div className="pb-1 pt-1">Category</div>
                      <div>
                        <span style={{ display: "block", width: "100%" }}>
                          <div className="app-primary-dropdown">
                            <MultipleSelectDropdown
                              multi={false}
                              color="#8A00E5"
                              placeholder="Select by community category"
                              className="dropdown-select  mb-2"
                              options={
                                options?.length > 0 &&
                                options?.map((value) => ({
                                  name: value.name,
                                  id: value.id?.toString(),
                                }))
                              }
                              onChange={handleCategoryChange}
                              values={communityCategory}
                            />
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="pt-2 pb-2">
                      <div className="pb-1 pt-1">Tag</div>
                      <div>
                        <span style={{ display: "block", width: "100%" }}>
                          <div className="app-primary-dropdown">
                            <MultipleSelectDropdown
                              multi={false}
                              color="#8A00E5"
                              placeholder="Select by Community Tag"
                              className="dropdown-select mb-2"
                              options={
                                communityTagOptions?.length > 0 &&
                                communityTagOptions?.map((tag) => ({
                                  name: tag.name,
                                  id: tag.id,
                                }))
                              }
                              onChange={handleCommunityTag}
                              values={currentCommunityTag}
                            />
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="pt-2 pb-2">
                      <div className="pb-1 pt-1">Channel</div>
                      <div>
                        <span style={{ display: "block", width: "100%" }}>
                          <div className="app-primary-dropdown">
                            <MultipleSelectDropdown
                              multi={false}
                              color="#8A00E5"
                              placeholder="Search by Community Channel"
                              className="dropdown-select mb-2"
                              options={
                                channels?.length > 0 &&
                                channels?.map((val) => ({
                                  name: val.name,
                                  id: val.id,
                                }))
                              }
                              values={channel}
                              onChange={handleChannelChange}
                            />
                          </div>
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="d-flex justify-content-end font-weight-bold pt-4">
                        <Button
                          className="app-secondary-btn ms-3 my-auto"
                          onClick={handleResetFilter}
                        >
                          Clear
                        </Button>
                        <Button
                          className="app-secondary-btn ms-3 my-auto"
                          onClick={() => setDropdownShow(false)}
                        >
                          {" "}
                          Cancel
                        </Button>

                        <Button
                          className="app-primary-btn ms-3"
                          onClick={handleFiltersApply}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Col>
          </Row>
        </Container>
        {!showLoader ? (
          <div
            className="pt-1 main-div"
            style={{
              paddingLeft: "0.7rem",
              paddingRight: "0.7rem",
              minHeight: "40rem",
            }}
          >
            {renderCommunities(communityData)}
          </div>
        ) : (
          <div style={{ maxWidth: "100%", height: "35rem" }}>
            <Loader />
          </div>
        )}
        <div
          className="w-100 mt-3  pb-2  d-flex justify-content-end"
          style={{ paddingRight: "2rem" }}
        >
          <ResponsivePagination
            current={currentPage}
            total={totalPagesVal}
            onPageChange={handlePageChange}
          />
        </div>
        <CommunityDetailsPage
          comId={activeComId}
          hide={handleClose}
          handleClose={handleClose}
          show={show}
          setLevel={setLevel}
        />
      </div>
      {showEmployeeModal === true ? (
        <EmployeeShowModal
          show={showEmployeeModal}
          handleClose={handleCloseModal}
          onHide={handleCloseModal}
          title={"View Members"}
          communityData={activeComData}
          setLevel={setLevel}
        />
      ) : (
        ""
      )}
    </div>
  );
}
