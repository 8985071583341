import React from "react";
import { useEffect, useState } from "react";
import { getHostUrl, getBaseUrl } from "../config/environment";
import { getData } from "../services/apiService";
import TrainingsUserScreen from "../components/TrainingsUserScreen";
import TrainingComp from "../components/TrainingComp/TrainingComp";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const TrainingsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let flag = window.location.href.includes("v2");
  const [v2Data, setv2Data] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const res1 = await getData(
        `${getBaseUrl()}/configurations/trainings/trainingsPage.json`
      );
      await setv2Data(res1.data);

      const res = await getData(
        `${getHostUrl()}training?include=apps,tools,process,trainers&use_pagination=false`
      );

      await setData(res.data);
    })();
  }, []);

  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <div>
            <h2 className="fw-bold">Trainings</h2>
          </div>
          <div>
            <Button
              className="app-tertiary-btn ms-3 mt-1"
              onClick={() => {
                if (state?.prevPath) {
                  window.location.assign(state?.prevPath);
                } else {
                  navigate("/");
                }
              }}
            >
              Close page X
            </Button>
          </div>
        </div>
        {v2Data && data ? (
          <div className="mt-3">
            {flag ? (
              <TrainingComp source={v2Data} />
            ) : (
              <TrainingsUserScreen source={data} />
            )}
          </div>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingsPage;
