import { useEffect, useState } from "react";
import "../../assets/scss/common.scss";
import { Table } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import "./CommunitiesAdminScreen.scss";
import Avatar from "react-avatar";
import AddNewCommunity from "./AddNewCommunity";
import { UserModal } from "../../utils/toolsUtils";
import { DeleteCommunityModal } from "../../utils/communitiesUtil";
import { getData } from "../../services/apiService";
import { getHostUrl, getBaseUrl } from "../../config/environment";
import CommunityUserPage from "../CommunityUserPages/CommunityUserPage";
import { EmployeeShowModal } from "../CommonComponents/EmployeeShowModal";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import Loader from "../Loader/Loader";
import "react-bootstrap-typeahead/css/Typeahead.css";

export default function CommunityTable({ communityData }) {
  const [pageLevel, setPageLevel] = useState("communities");
  const [communityDataValue, setCommunityDataValue] = useState(
    communityData["data"]["data"]
  );

  const initialCommunityData = communityData["data"]["data"];
  // eslint-disable-next-line
  const [expertsData, setExpertsData] = useState();
  const [showUserModal, setShowUserModal] = useState(false);
  const [usersData, setUsersData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [valueId, setValueId] = useState();
  const [userCommunityData, setUserCommunityData] = useState();
  const [communityEditData, setEditCommunityData] = useState();
  const [deleteModalData, setDeleteModalData] = useState();
  const [updateData, setUpdateData] = useState(true);
  const [communityHeads, setCommunityHeads] = useState();
  const [applicationData, setApplicationData] = useState();
  const [processData, setProcessData] = useState();
  const [includedData, setIncludedData] = useState();
  const [toolsData, setToolsData] = useState();
  const [res, setRes] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [memberData, setMemberData] = useState();
  const [nameQuery, setNameQuery] = useState("");
  const [linkQuery, setLinkQuery] = useState("");
  const [categoryQuery, setCategoryQuery] = useState([]);
  const [communityTags, setCommunityTags] = useState([]);

  const categoryOptions = [
    { id: 1, name: "Applications" },
    { id: 2, name: "Processes" },
    { id: 3, name: "Tools" },
    { id: 4, name: "Miscellaneous" },
  ];

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  // eslint-disable-next-line
  const communityUsers = {};
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    setPageLevel(url.searchParams.get("page"));
  }, []);

  if (updateData) {
    const param = `${getHostUrl()}community?include=apps,members,heads,communities_process,channel_info,tools&use_pagination=false`;
    const communityUsers = {};
    const communityMembers = {};

    (async function getResponseData() {
      const res = await getData(param);
      await setIncludedData(res.data["included"]);
      await setCommunityDataValue(res.data.data);

      res.data["included"]?.forEach((users) => {
        if (users.type === "heads") {
          const community_id = users.attributes["community-id"];
          if (communityUsers[community_id]) {
            communityUsers[community_id].push(users);
          } else {
            communityUsers[community_id] = [users];
          }
        }
      });

      res.data["included"]?.forEach((users) => {
        if (users.type === "members") {
          const communityMember_id = users.attributes["community-id"];
          if (communityMembers[communityMember_id]) {
            communityMembers[communityMember_id].push(users);
          } else {
            communityMembers[communityMember_id] = [users];
          }
        }
      });
      await setCommunityHeads(communityUsers);
    })();

    setUpdateData(false);
  }

  async function getResponse() {
    const applicationData = `${getHostUrl()}application?use_pagination=false`;
    const processData = `${getHostUrl()}process?use_pagination=false`;
    const toolsData = `${getHostUrl()}tools?use_pagination=false`;
    const res = await getData(
      `${getBaseUrl()}/configurations/communityTags.json`
    );
    const applicationRes = await getData(applicationData);
    const processRes = await getData(processData);
    const toolsRes = await getData(toolsData);
    try {
      if (applicationRes && processRes) {
        await setApplicationData(applicationRes.data);
        await setProcessData(processRes.data);
        await setToolsData(toolsRes.data);
        await setCommunityTags(res.data);
        setRes(true);
      }
    } catch (error) {
      if (error) {
        setApplicationData([]);
        setProcessData([]);
        setToolsData([]);
      }
    }
  }

  useEffect(() => {
    const communityHeads = {};
    const communityMembers = {};

    includedData?.forEach((data) => {
      if (data.type === "heads") {
        const community_id = data.attributes["community-id"];
        if (communityHeads[community_id]) {
          communityHeads[community_id].push(data);
        } else {
          communityHeads[community_id] = [data];
        }
      }
    });
    includedData?.forEach((data) => {
      if (data?.type === "members") {
        const communityMember_id = data?.attributes["community-id"];
        if (communityMembers[communityMember_id]) {
          communityMembers[communityMember_id].push(data);
        } else {
          communityMembers[communityMember_id] = [data];
        }
      }
    });

    setExpertsData(communityUsers);
    setCommunityHeads(communityHeads);
    getResponse();
    // eslint-disable-next-line
  }, []);

  const navigateFun = () => {
    const url = new URL(window.location.href);
    url.searchParams.get("page");
    if (url.searchParams.get("page") === "communities") {
      navigate("/manage_users");
    } else {
      setPageLevel("communities");
      addQueryParam("page", "communities");
    }
  };

  const finalMemberData = {
    data: memberData,
  };

  const nameSearch = (query) => {
    if (
      query.length.toString() === "0" &&
      linkQuery?.length.toString() === "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(initialCommunityData);
    } else if (
      query.length.toString() !== "0" &&
      linkQuery?.length.toString() === "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter((item) =>
          item.attributes["community-name"].toLowerCase().includes(query)
        )
      );
    } else if (
      query.length.toString() === "0" &&
      linkQuery?.length.toString() !== "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter((item) =>
          item.attributes["community-link"]
            .toLowerCase()
            .includes(linkQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      linkQuery?.length.toString() === "0" &&
      categoryQuery.length.toString() !== "0"
    ) {
      let tempList = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setCommunityDataValue(tempList);
    } else if (
      query.length.toString() !== "0" &&
      linkQuery?.length.toString() !== "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter(
          (item) =>
            item.attributes["community-name"].toLowerCase().includes(query) &&
            item.attributes["community-link"]
              .toLowerCase()
              .includes(linkQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      linkQuery?.length.toString() !== "0" &&
      categoryQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialCommunityData?.filter((item) =>
        item.attributes["community-link"]
          .toLowerCase()
          .includes(linkQuery.toLowerCase())
      );
      let tempList2 = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setCommunityDataValue(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["community-name"] ===
                elem2.attributes["community-name"]
          )
        )
      );
    } else if (
      query.length.toString() !== "0" &&
      linkQuery?.length.toString() === "0" &&
      categoryQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialCommunityData?.filter((item) =>
        item.attributes["community-name"].toLowerCase().includes(query)
      );
      let tempList2 = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setCommunityDataValue(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["community-name"] ===
                elem2.attributes["community-name"]
          )
        )
      );
    } else if (
      query.length.toString() > "0" &&
      linkQuery?.length.toString() > "0" &&
      categoryQuery.length.toString() > "0"
    ) {
      let tempList = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });

      let arr1 = tempList.concat(
        initialCommunityData?.filter((item) =>
          item.attributes["community-name"].toLowerCase().includes(query)
        ),
        initialCommunityData?.filter((item) =>
          item.attributes["community-link"]
            .toLowerCase()
            .includes(linkQuery.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(
            initialCommunityData?.filter((item) => item.id === elem.id)[0]
          );
        }
        return false;
      });

      setCommunityDataValue(finalList);
    }
  };

  const linkSearch = (query) => {
    if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(initialCommunityData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter((item) =>
          item.attributes["community-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter((item) =>
          item.attributes["community-link"]
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      categoryQuery.length.toString() !== "0"
    ) {
      let tempList = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setCommunityDataValue(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() !== "0" &&
      categoryQuery.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter(
          (item) =>
            item.attributes["community-name"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["community-link"]
              .toLowerCase()
              .includes(query.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      categoryQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialCommunityData?.filter((item) =>
        item.attributes["community-link"]
          .toLowerCase()
          .includes(query.toLowerCase())
      );
      let tempList2 = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setCommunityDataValue(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["community-name"] ===
                elem2.attributes["community-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      categoryQuery.length.toString() !== "0"
    ) {
      let tempList1 = initialCommunityData?.filter((item) =>
        item.attributes["community-name"]
          .toLowerCase()
          .includes(nameQuery.toLowerCase())
      );

      let tempList2 = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setCommunityDataValue(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["community-name"] ===
                elem2.attributes["community-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      query?.length.toString() > "0" &&
      categoryQuery.length.toString() > "0"
    ) {
      let tempList = [];
      initialCommunityData?.map((elem) => {
        if (
          categoryQuery[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          categoryQuery[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });

      let arr1 = tempList.concat(
        initialCommunityData?.filter((item) =>
          item.attributes["community-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        ),
        initialCommunityData?.filter((item) =>
          item.attributes["community-link"]
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(
            initialCommunityData?.filter((item) => item.id === elem.id)[0]
          );
        }
        return false;
      });

      setCommunityDataValue(finalList);
    }
  };

  const categorySearch = (value) => {
    setCategoryQuery(value);
    if (
      nameQuery?.length.toString() === "0" &&
      linkQuery?.length.toString() === "0" &&
      value.length.toString() === "0"
    ) {
      setCommunityDataValue(initialCommunityData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      linkQuery?.length.toString() === "0" &&
      value.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter((item) =>
          item.attributes["community-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      linkQuery?.length.toString() !== "0" &&
      value.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter((item) =>
          item.attributes["community-link"]
            .toLowerCase()
            .includes(linkQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      linkQuery?.length.toString() === "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList = [];
      initialCommunityData?.map((elem) => {
        const appIsFound = value?.some((elem) => {
          if (elem.id.toString() === "1") {
            return true;
          }
          return false;
        });
        const processIsFound = value?.some((elem) => {
          if (elem.id.toString() === "2") {
            return true;
          }
          return false;
        });
        const toolIsFound = value?.some((elem) => {
          if (elem.id.toString() === "3") {
            return true;
          }
          return false;
        });

        const miscIsFound = value?.some((elem) => {
          if (elem.id.toString() === "4") {
            return true;
          }
          return false;
        });
        if (
          appIsFound &&
          appIsFound === true &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          processIsFound &&
          processIsFound === true &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          toolIsFound &&
          toolIsFound === true &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          miscIsFound &&
          miscIsFound === true &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setCommunityDataValue(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      linkQuery?.length.toString() !== "0" &&
      value.length.toString() === "0"
    ) {
      setCommunityDataValue(
        initialCommunityData?.filter(
          (item) =>
            item.attributes["community-name"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["community-link"]
              .toLowerCase()
              .includes(linkQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      linkQuery?.length.toString() !== "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList1 = initialCommunityData?.filter((item) =>
        item.attributes["community-link"]
          .toLowerCase()
          .includes(linkQuery.toLowerCase())
      );
      let tempList2 = [];
      initialCommunityData?.map((elem) => {
        const appIsFound = value?.some((elem) => {
          if (elem.id.toString() === "1") {
            return true;
          }
          return false;
        });
        const processIsFound = value?.some((elem) => {
          if (elem.id.toString() === "2") {
            return true;
          }
          return false;
        });
        const toolIsFound = value?.some((elem) => {
          if (elem.id.toString() === "3") {
            return true;
          }
          return false;
        });

        const miscIsFound = value?.some((elem) => {
          if (elem.id.toString() === "4") {
            return true;
          }
          return false;
        });

        if (
          appIsFound &&
          appIsFound === true &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          processIsFound &&
          processIsFound === true &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          toolIsFound &&
          toolIsFound === true &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          miscIsFound &&
          miscIsFound === true &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setCommunityDataValue(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["community-name"] ===
                elem2.attributes["community-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      linkQuery?.length.toString() === "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList1 = initialCommunityData?.filter((item) =>
        item.attributes["community-name"]
          .toLowerCase()
          .includes(nameQuery.toLowerCase())
      );

      let tempList2 = [];
      initialCommunityData?.map((elem) => {
        const appIsFound = value?.some((elem) => {
          if (elem.id.toString() === "1") {
            return true;
          }
          return false;
        });
        const processIsFound = value?.some((elem) => {
          if (elem.id.toString() === "2") {
            return true;
          }
          return false;
        });
        const toolIsFound = value?.some((elem) => {
          if (elem.id.toString() === "3") {
            return true;
          }
          return false;
        });

        const miscIsFound = value?.some((elem) => {
          if (elem.id.toString() === "4") {
            return true;
          }
          return false;
        });

        if (
          appIsFound &&
          appIsFound === true &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          processIsFound &&
          processIsFound === true &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          toolIsFound &&
          toolIsFound === true &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList2.push(elem);
        } else if (
          miscIsFound &&
          miscIsFound === true &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setCommunityDataValue(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["community-name"] ===
                elem2.attributes["community-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      linkQuery?.length.toString() > "0" &&
      value.length.toString() > "0"
    ) {
      let tempList = [];
      initialCommunityData?.map((elem) => {
        if (
          value[0].id.toString() === "1" &&
          elem.relationships.apps.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          value[0].id.toString() === "2" &&
          elem.relationships["communities-process"].data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          value[0].id.toString() === "3" &&
          elem.relationships.tools.data.length.toString() > "0"
        ) {
          tempList.push(elem);
        } else if (
          value[0].id.toString() === "4" &&
          elem.relationships.apps.data.length.toString() === "0" &&
          elem.relationships["communities-process"].data.length.toString() ===
            "0" &&
          elem.relationships.tools.data.length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });

      let arr1 = tempList.concat(
        initialCommunityData?.filter((item) =>
          item.attributes["community-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        ),
        initialCommunityData?.filter((item) =>
          item.attributes["community-link"]
            .toLowerCase()
            .includes(linkQuery.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(
            initialCommunityData?.filter((item) => item.id === elem.id)[0]
          );
        }
        return false;
      });

      setCommunityDataValue(finalList);
    }
  };

  return (
    <div>
      <div className="back-icon d-flex">
        <Nav.Link onClick={() => navigateFun()}>
          {" "}
          <ImageSvgs name="goBack" />
        </Nav.Link>
        <span className="navigate-back"> Back </span>
      </div>
      {pageLevel === "communities" ? (
        <>
          <div className="sub-bar d-flex justify-content-between">
            <p className="main-title">
              Communities({communityDataValue.length})
            </p>
            <div className="search-div d-flex">
              <Button
                className="app-secondary-btn"
                onClick={() => {
                  setPageLevel("addCommunities");
                  addQueryParam("page", "addCommunities");
                }}
              >
                Add New Community
              </Button>
            </div>
          </div>

          <div className="community-table">
            <div className="user-community-table table table-responsive">
              <div className="table-responsive">
                {res ? (
                  <Table className="main-community-table" striped>
                    <thead className="user-table-head">
                      <tr>
                        <th>Community Name </th>
                        <th>Community Link</th>
                        <th>Community Owners </th>
                        <th>Community Members </th>
                        <th>Category </th>
                        <th>Action</th>
                      </tr>
                      <tr style={{ height: "3.5rem" }}>
                        <th>
                          <div className="app-primary-input-field mb-2">
                            <input
                              className="me-3 w-100"
                              type="search"
                              placeholder=" Search by name..."
                              value={nameQuery}
                              onChange={(e) => {
                                setNameQuery(e.target.value);
                                nameSearch(e.target.value.toLowerCase());
                              }}
                            />
                          </div>
                        </th>
                        <th>
                          <div className="app-primary-input-field mb-2">
                            <input
                              className="me-3 w-100"
                              type="search"
                              placeholder=" Search by link..."
                              value={linkQuery}
                              onChange={(e) => {
                                setLinkQuery(e.target.value);
                                linkSearch(e.target.value.toLowerCase());
                              }}
                            />
                          </div>
                        </th>
                        <th style={{ textAlign: "left" }}>
                          <div className="app-primary-input-field mb-2">
                            <input
                              disabled
                              className="me-3 w-100"
                              type="search"
                              placeholder="Disabled"
                            />
                          </div>
                        </th>
                        <th>
                          <div className="app-primary-input-field mb-2">
                            <input
                              disabled
                              className="me-3 w-100"
                              type="search"
                              placeholder="Disabled"
                            />
                          </div>
                        </th>
                        <th>
                          <div
                            className="app-primary-dropdown mb-2"
                            style={{ position: "absolute", width: "19%" }}
                          >
                            <Typeahead
                              multiple={false}
                              selected={categoryQuery}
                              labelKey="name"
                              emptyLabel=""
                              placeholder="Search by category..."
                              id="typeAhead"
                              clearButton
                              searchable
                              onChange={categorySearch}
                              options={categoryOptions}
                            />
                          </div>
                        </th>
                        <th>
                          <div className="app-primary-input-field mb-2">
                            <input
                              disabled
                              className="me-3 w-100"
                              type="search"
                              placeholder="Disabled"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {communityDataValue && communityDataValue.length > 0 ? (
                        <>
                          {communityDataValue
                            ?.sort((a, b) =>
                              a.attributes["community-name"].localeCompare(
                                b.attributes["community-name"]
                              )
                            )
                            ?.map((value) => (
                              <tr key={value?.id} className="table-striped-bg">
                                <td
                                  onClick={() => (
                                    // eslint-disable-next-line
                                    setValueId(value.id),
                                    setUserCommunityData(value)
                                  )}
                                >
                                  <span className="app-table-name">
                                    <Link
                                      className="app-table-name"
                                      style={{ textDecoration: "none" }}
                                      to={`/landscape/communities?page=communities${value.id}`}
                                      state={{ prevPath: window.location.href }}
                                    >
                                      {value.attributes["community-name"]}
                                    </Link>
                                  </span>
                                </td>
                                <td>{value.attributes["community-link"]}</td>
                                <td>
                                  <>
                                    <span>
                                      {communityHeads &&
                                      communityHeads[value.id] ? (
                                        <>
                                          {communityHeads[value.id]?.length <=
                                          3 ? (
                                            <>
                                              {communityHeads[value.id]?.map(
                                                (users, length) => {
                                                  return (
                                                    <span
                                                      className="px-1"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        navigate(
                                                          `/employee_relation?id=${users?.attributes["employee-id"]}`
                                                        );
                                                      }}
                                                    >
                                                      {users?.attributes
                                                        .employee?.data
                                                        .attributes[
                                                        "profile-picture"
                                                      ] ? (
                                                        <img
                                                          className="my-auto"
                                                          src={`${getBaseUrl()}${
                                                            users?.attributes
                                                              .employee?.data
                                                              .attributes[
                                                              "profile-picture"
                                                            ]
                                                          }`}
                                                          style={{
                                                            height: "2rem",
                                                            width: "2rem",
                                                            border:
                                                              "1.5px solid black",
                                                            borderRadius:
                                                              "7rem",
                                                            objectFit:
                                                              "contain",
                                                          }}
                                                          alt="User Img"
                                                        />
                                                      ) : (
                                                        <Avatar
                                                          name={
                                                            users.attributes
                                                              .employee &&
                                                            users.attributes
                                                              .employee.data
                                                              ?.attributes?.[
                                                              "preferred-name"
                                                            ]
                                                          }
                                                          size="32"
                                                          round={true}
                                                        />
                                                      )}
                                                    </span>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {communityHeads[value.id]?.map(
                                                // eslint-disable-next-line
                                                (expert, index) => {
                                                  if (index <= 2) {
                                                    return (
                                                      <span
                                                        className="px-1"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          navigate(
                                                            `/employee_relation?id=${expert.attributes["employee-id"]}`
                                                          );
                                                        }}
                                                      >
                                                        {expert["attributes"]
                                                          ?.employee.data
                                                          .attributes?.[
                                                          "profile-picture"
                                                        ] ? (
                                                          <img
                                                            className="my-auto"
                                                            src={`${getBaseUrl()}${
                                                              expert[
                                                                "attributes"
                                                              ]?.employee.data
                                                                .attributes?.[
                                                                "profile-picture"
                                                              ]
                                                            }`}
                                                            style={{
                                                              height: "2rem",
                                                              width: "2rem",
                                                              border:
                                                                "1.5px solid black",
                                                              borderRadius:
                                                                "7rem",
                                                              objectFit:
                                                                "contain",
                                                            }}
                                                            alt="User Img"
                                                          />
                                                        ) : (
                                                          <Avatar
                                                            name={
                                                              expert[
                                                                "attributes"
                                                              ]?.employee?.data
                                                                .attributes?.[
                                                                "preferred-name"
                                                              ]
                                                            }
                                                            size="32"
                                                            round={true}
                                                          />
                                                        )}
                                                      </span>
                                                    );
                                                  }
                                                }
                                              )}
                                              <button
                                                className="more-user-btn"
                                                onClick={() => {
                                                  setUsersData(
                                                    communityHeads[value.id]
                                                  );
                                                  setShowUserModal(true);
                                                }}
                                              >
                                                +{" "}
                                                {communityHeads[value.id]
                                                  ?.length - 3}{" "}
                                                more
                                              </button>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <span className="ms-3 fw-bold fs-5">
                                          -
                                        </span>
                                      )}
                                    </span>
                                  </>
                                </td>
                                <td>
                                  {" "}
                                  <>
                                    {value?.attributes?.["members-count"] >
                                    0 ? (
                                      <span>
                                        <button
                                          className="more-user-btn"
                                          onClick={() => {
                                            setMemberData(value);
                                            setShowMemberModal(true);
                                          }}
                                        >
                                          {value?.attributes[
                                            "community-type"
                                          ] === 0 ? (
                                            <>
                                              {value?.attributes?.[
                                                "members-count"
                                              ] + " "}
                                              {value?.attributes?.[
                                                "members-count"
                                              ] === 1
                                                ? "Member"
                                                : "Members"}
                                            </>
                                          ) : (
                                            <>
                                              {value?.relationships?.members
                                                ?.data.length + " "}
                                              {value?.relationships?.members
                                                ?.data.length === 1
                                                ? "Member"
                                                : "Members"}
                                            </>
                                          )}
                                        </button>
                                      </span>
                                    ) : (
                                      <span className="ms-3 fw-bold fs-5">
                                        -
                                      </span>
                                    )}
                                  </>
                                </td>

                                <td>
                                  {value?.relationships["apps"].data.length ===
                                  0
                                    ? null
                                    : value?.relationships["apps"].data.length >
                                        0 &&
                                      value?.relationships[
                                        "communities-process"
                                      ].data.length === 0 &&
                                      value?.relationships["tools"].data
                                        .length === 0
                                    ? "Applications"
                                    : "Applications, "}
                                  {value?.relationships["communities-process"]
                                    .data.length === 0
                                    ? null
                                    : value?.relationships["apps"].data
                                        .length === 0 &&
                                      value?.relationships[
                                        "communities-process"
                                      ].data.length > 0 &&
                                      value?.relationships["tools"].data
                                        .length === 0
                                    ? "Processes"
                                    : value?.relationships["apps"].data.length >
                                        0 &&
                                      value?.relationships[
                                        "communities-process"
                                      ].data.length > 0 &&
                                      value?.relationships["tools"].data
                                        .length === 0
                                    ? "Processes"
                                    : value?.relationships[
                                        "communities-process"
                                      ].data.length > 0 &&
                                      value?.relationships["tools"].data
                                        .length > 0
                                    ? "Processes, "
                                    : "Processes, "}
                                  {value?.relationships["tools"].data.length > 0
                                    ? "Tools"
                                    : null}
                                  {value?.relationships["communities-process"]
                                    .data.length === 0 &&
                                  value?.relationships["apps"].data.length ===
                                    0 &&
                                  value?.relationships["tools"].data.length ===
                                    0
                                    ? "Miscellaneous"
                                    : null}
                                </td>
                                <td className="d-flex justify-content-right">
                                  <Nav.Link
                                    onClick={() => {
                                      setPageLevel("editCommunity");
                                      addQueryParam("page", "editCommunity");
                                      setEditCommunityData(value);
                                    }}
                                  >
                                    <span className="px-2">
                                      <ImageSvgs name="edit" />
                                    </span>
                                  </Nav.Link>
                                  <button
                                    className="border-0 bg-transparent mb-auto"
                                    onClick={() => {
                                      setDeleteModal(true);
                                      setDeleteModalData(value);
                                    }}
                                  >
                                    <span className="px-2">
                                      <ImageSvgs name="delete" />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </>
                      ) : (
                        <tr className="w-100">
                          <td
                            className="w-100"
                            style={{
                              textAlign: "center",
                              backgroundColor: "#e9ecef ",
                            }}
                          >
                            <p className="fw-bold mt-2 mb-1">
                              No matching records found.
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ maxWidth: "100%", height: "35rem" }}>
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : pageLevel === "addCommunities" ? (
        <div className="add-community">
          <AddNewCommunity
            communityPageName={"Add New Community"}
            navigateFun={navigateFun}
            setPageLevel={setPageLevel}
            setUpdateData={setUpdateData}
            includedData={includedData}
            pageLevel={pageLevel}
            communityDataValue={communityDataValue && communityDataValue}
            applicationData={applicationData && applicationData}
            processData={processData && processData}
            toolsData={toolsData && toolsData}
            communityTags={communityTags}
          />
        </div>
      ) : pageLevel === "editCommunity" ? (
        <AddNewCommunity
          communityPageName={"Edit Community"}
          navigateFun={navigateFun}
          setPageLevel={setPageLevel}
          editCommunityData={communityEditData}
          setUpdateData={setUpdateData}
          includedData={includedData}
          pageLevel={pageLevel}
          communityDataValue={communityDataValue && communityDataValue}
          applicationData={applicationData && applicationData}
          processData={processData && processData}
          toolsData={toolsData && toolsData}
          communityTags={communityTags}
        />
      ) : pageLevel === `communities${valueId}` ? (
        <CommunityUserPage
          userCommunityData={userCommunityData}
          includedData={includedData}
        />
      ) : (
        ""
      )}
      {deleteModal === true ? (
        <DeleteCommunityModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          deleteModalData={deleteModalData}
          setUpdateData={setUpdateData}
        />
      ) : (
        " "
      )}
      {showMemberModal === true ? (
        <EmployeeShowModal
          show={showMemberModal}
          handleClose={() => setShowMemberModal(false)}
          title={"View Members"}
          communityData={finalMemberData}
        />
      ) : (
        " "
      )}
      {showUserModal === true ? (
        <UserModal
          title={"Community Owners"}
          heading={"List of Community Owners:"}
          listData={usersData}
          show={showUserModal}
          onHide={() => setShowUserModal(false)}
        />
      ) : (
        " "
      )}
    </div>
  );
}
