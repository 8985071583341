import { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MultipleSelectDropdown } from "../CommonComponents/MultipleSelect";
import Button from "react-bootstrap/Button";
import "../../assets/scss/common.scss";
import "./ProcessAdminPage.scss";
import Select from "react-dropdown-select";
import { EmployeeModal } from "../CommonComponents/EmployeeModal";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { addProcess, updateProcessData } from "../../utils/processUtil";
import { now } from "lodash";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      console.log("error");
      reject(error);
    };
  });
};

export default function AddAndEditProcess(props) {
  const pageLevel = props?.setPageLevel;
  const checkPageLevel = props?.pageLevel;
  const updateData = props?.setFlag;
  const processAll = props?.processData;
  const trainingAll = props?.trainingData;
  const communityAll = props?.communityData;
  const applicationAll = props?.applicationData;
  const organizationAll = props?.organizationData;
  // eslint-disable-next-line
  const setProcessViewData = props?.setProcessViewData;
  const editData = props?.processEditData;
  const includedData = props?.processIncludedData;
  const initialProcessData = props?.initialProcessData;
  // eslint-disable-next-line
  const setAddUpdateData = props?.setAddProcessData;
  const formDataString = sessionStorage.getItem("formData");
  const formDataSet = JSON.parse(formDataString || "{}");
  const template64 = useRef(
    Object.keys(formDataSet).length !== 0
      ? formDataSet?.data && formDataSet?.data.attributes.template
      : editData !== null
      ? editData?.attributes.template
      : null
  );
  const [templateErr, setTemplateErr] = useState(false);
  const inputRef = useRef(null);
  const hash = require("object-hash");
  const [nameErr, setNameErr] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeputyModal, setShowDeputyModal] = useState(false);
  const [orgQuery, setOrgQuery] = useState("");
  // const [organData, setOrganData] = useState();
  const [descriptionError, setDescriptionError] = useState("");
  const [ownerCheck, setOwnerCheck] = useState("");
  const [loading, setLoading] = useState(false);

  const periodicity = [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Quarterly" },
    { id: 3, name: "Annually" },
  ];

  const [formData, setFormData] = useState({
    processName:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes.title
        : editData !== null
        ? editData?.attributes.title
        : null,
    processDesc:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes.desc
        : editData !== null
        ? editData?.attributes.desc
        : null,
    template_url:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes.template_url
        : editData !== null
        ? editData?.attributes.template_url
        : null,

    handbookLink:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes["handbook_link_url"]
        : editData?.attributes["handbook-link-url"] !== undefined
        ? editData?.attributes["handbook-link-url"]
        : null,
    wikiLink:
      Object.keys(formDataSet).length !== 0
        ? formDataSet?.data && formDataSet?.data.attributes["wiki_link_url"]
        : editData?.attributes["wiki-link-url"] !== undefined
        ? editData?.attributes["wiki-link-url"]
        : null,
  });

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.processName || formData.processName === "") {
      errors.processName = "Required*";
    }

    if (!formData.processDesc || formData.processDesc === "") {
      errors.processDesc = "Required*";
    }
    setFormErrors(errors);
    return errors;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOwnerChange = () => {
    if (processOwner.length > 0) {
      setOwnerCheck("");
    } else {
      setOwnerCheck("Required");
    }
  };

  const dataU = [
    { id: 1, name: "U + 0" },
    { id: 2, name: "U + 1" },
    { id: 3, name: "U + 2" },
    { id: 4, name: "U + 3" },
    { id: 5, name: "U + 4" },
    { id: 6, name: "U + 5" },
    { id: 7, name: "U + 6" },
    { id: 8, name: "U + 7" },
    { id: 9, name: "U + 8" },
    { id: 10, name: "U + 9" },
    { id: 11, name: "U + 10" },
    { id: 12, name: "U + 11" },
    { id: 13, name: "U + 12" },
    { id: 14, name: "U + 13" },
    { id: 15, name: "U + 14" },
    { id: 16, name: "U + 15" },
  ];

  const dateSelect = [
    { id: 1, name: "12:00 AM" },
    { id: 2, name: "12:30 AM" },
    { id: 3, name: "01:00 AM" },
    { id: 4, name: "01:30 AM" },
    { id: 5, name: "02:00 AM" },
    { id: 6, name: "02:30 AM" },
    { id: 7, name: "03:00 AM" },
    { id: 8, name: "03:30 AM" },
    { id: 9, name: "04:30 AM" },
    { id: 10, name: "05:00 AM" },
    { id: 11, name: "05:30 AM" },
    { id: 12, name: "06:00 AM" },
    { id: 13, name: "06:30 AM" },
    { id: 14, name: "07:00 AM" },
    { id: 15, name: "07:30 AM" },
    { id: 16, name: "08:00 AM" },
    { id: 17, name: "08:30 AM" },
    { id: 18, name: "09:00 AM" },
    { id: 19, name: "09:30 AM" },
    { id: 20, name: "10:00 AM" },
    { id: 21, name: "10:30 AM" },
    { id: 22, name: "11:00 AM" },
    { id: 23, name: "11:30 AM" },
    { id: 24, name: "12:00 PM" },
    { id: 25, name: "12:30 PM" },
    { id: 26, name: "01:00 PM" },
    { id: 27, name: "01:30 PM" },
    { id: 28, name: "02:00 PM" },
    { id: 29, name: "02:30 PM" },
    { id: 30, name: "03:00 PM" },
    { id: 31, name: "03:30 PM" },
    { id: 32, name: "04:30 PM" },
    { id: 33, name: "05:00 PM" },
    { id: 34, name: "05:30 PM" },
    { id: 35, name: "06:00 PM" },
    { id: 36, name: "06:30 PM" },
    { id: 37, name: "07:00 PM" },
    { id: 38, name: "07:30 PM" },
    { id: 39, name: "08:00 PM" },
    { id: 40, name: "08:30 PM" },
    { id: 41, name: "09:00 PM" },
    { id: 42, name: "09:30 PM" },
    { id: 43, name: "10:00 PM" },
    { id: 44, name: "10:30 PM" },
    { id: 45, name: "11:00 PM" },
    { id: 46, name: "11:30 PM" },
  ];

  const tempOwners =
    editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              includedData?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-owners"] === true &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = includedData?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];
            return {
              type: "owners",
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "preferred-name": `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
                "employee-id": ownerData?.attributes["employee-id"],
                "is-owners": true,
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
              },

              id: ownerData?.id,
            };
          })
      : [];

  const tempDeputy =
    editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              includedData?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-owners"] === false &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )

          .map((elem) => {
            let ownerData = includedData?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];

            return {
              type: "owners",
              "preferred-name": `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              "email-id":
                ownerData?.attributes.employee.data.attributes["email-id"],
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "employee-id": ownerData?.attributes["employee-id"],
                "is-owners": false,
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
              },
              id: ownerData?.id,
            };
          })
      : [];

  const tempDepartment =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.orgs.length > 0
      ? formDataSet?.data?.relationships.orgs
      : editData && editData?.relationships.orgs.data?.length > 0
      ? editData?.relationships.orgs.data?.map((data) => {
          let deptData = includedData?.filter(
            (val) =>
              val?.type === "orgs" &&
              val?.id?.toString() === data?.id?.toString()
          )[0];
          return {
            type: "orgs",
            name: deptData?.attributes["org-master"].data.attributes[
              "org-title"
            ],
            attributes: {
              org_id: deptData?.attributes["org-id"]?.toString(),
            },
            id: deptData?.id?.toString(),
          };
        })
      : [];

  const organizationData =
    organizationAll &&
    organizationAll?.data.map((item) =>
      tempDepartment?.filter(
        (org) => org?.type === "organization" && org?.id === item?.id
      )[0]
        ? tempDepartment?.filter(
            (val) => val?.type === "organization" && val?.id === item?.id
          )[0]
        : {
            type: "orgs",
            name: item?.attributes["org-title"],
            attributes: {
              org_id: item?.id?.toString(),
            },
            id: null,
          }
    );

  const tempTraining =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.trainings.length > 0
      ? formDataSet?.data?.relationships.trainings
      : editData?.relationships.trainings?.data.length > 0
      ? editData?.relationships.trainings.data?.map((tr) => {
          let trainingData = includedData?.filter(
            (val) =>
              val?.attributes["training-master"] &&
              val?.attributes["training-master"].data.type === "training" &&
              val?.id === tr?.id
          )[0];
          return {
            type: "trainings",
            name: trainingData?.attributes["training-master"].data.attributes[
              "training-name"
            ],
            attributes: {
              training_id: trainingData?.attributes["training-id"].toString(),
            },
            id: trainingData?.id?.toString(),
          };
        })
      : [];

  const trainingData =
    trainingAll &&
    trainingAll?.data?.map((item) =>
      tempTraining?.filter(
        (tr) =>
          tr?.type === "training" && tr?.id?.toString() === item?.id?.toString()
      )[0]
        ? tempTraining?.filter(
            (val) =>
              val?.type === "training" &&
              val?.id?.toString() === item?.id?.toString()
          )[0]
        : {
            type: "trainings",
            name: item?.attributes["training-name"],
            attributes: {
              training_id: item?.id?.toString(),
            },
            id: null,
          }
    );

  const tempCommunity =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.communities.length > 0
      ? formDataSet?.data?.relationships.communities
      : editData?.relationships.communities?.data.length > 0
      ? editData?.relationships.communities.data?.map((com) => {
          let communityData = includedData?.filter(
            (val) => val?.type === "community" && val?.id === com?.id
          )[0];
          return {
            type: "community",
            name: communityData?.attributes["process-communities"].data
              .attributes["community-name"],
            attributes: {
              community_id:
                communityData?.attributes["community-id"]?.toString(),
            },
            id: communityData?.id?.toString(),
          };
        })
      : [];

  const communityData = communityAll?.data.map((item) =>
    tempCommunity?.filter(
      (com) =>
        com?.type === "community" &&
        com?.id?.toString() === item?.id?.toString()
    )[0]
      ? tempCommunity?.filter(
          (val) =>
            val.type === "community" &&
            val?.id?.toString() === item?.id?.toString()
        )[0]
      : {
          type: "community",
          name: item?.attributes["community-name"],
          attributes: {
            community_id: item?.id?.toString(),
          },
          id: null,
        }
  );

  const tempApplication =
    Object.keys(formDataSet).length !== 0 &&
    formDataSet?.data?.relationships.apps.length > 0
      ? formDataSet?.data?.relationships.apps
      : editData?.relationships.apps.data?.length > 0
      ? editData?.relationships.apps.data?.map((app) => {
          let applicationData = includedData?.filter(
            (val) => val?.type === "application" && val?.id === app?.id
          )[0];
          return {
            type: "application",
            name: applicationData?.attributes["apps-process"].data.attributes[
              "app-title"
            ],
            attributes: {
              application_id:
                applicationData?.attributes["application-id"]?.toString(),
            },
            id: applicationData?.id?.toString(),
          };
        })
      : [];

  const applicationData =
    applicationAll &&
    applicationAll?.data?.map((item) =>
      tempApplication?.filter(
        (app) =>
          app?.type === "application" &&
          app.id?.toString() === item?.id?.toString()
      )[0]
        ? tempApplication?.filter(
            (val) =>
              val?.type === "application" &&
              val?.id?.toString() === item?.id?.toString()
          )[0]
        : {
            type: "application",
            name: item?.attributes["app-title"],
            attributes: {
              application_id: item?.id?.toString(),
            },
            id: null,
          }
    );

  // eslint-disable-next-line
  const tempDate =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data?.attributes
      ? // eslint-disable-next-line
        dataU.filter((day) => {
          let dayValue = formDataSet?.data?.attributes["process_day"];
          if (day.name === dayValue) {
            return {
              id: day?.id,
              name: day?.name,
            };
          }
        })
      : editData
      ? // eslint-disable-next-line
        dataU.filter((day) => {
          let dayValue = editData && editData?.attributes["process-day"];
          if (day.name === dayValue) {
            return {
              id: day?.id,
              name: day?.name,
            };
          }
        })
      : "";

  // eslint-disable-next-line
  const tempTime =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data.attributes
      ? // eslint-disable-next-line
        dateSelect.filter((date) => {
          let timeValue = formDataSet?.data?.attributes["process_time"];
          if (date.name === timeValue) {
            return {
              id: date?.id,
              name: date?.name,
            };
          }
        })
      : editData
      ? // eslint-disable-next-line
        dateSelect.filter((date) => {
          let timeValue = editData && editData?.attributes["process-time"];
          if (date.name === timeValue) {
            return {
              id: date?.id,
              name: date?.name,
            };
          }
        })
      : "";

  const tempPeriod =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data.attributes
      ? // eslint-disable-next-line
        periodicity.filter((period) => {
          let periodId = editData && editData?.attributes["periodicity_period"];
          if (periodId === period.id) {
            return {
              type: "period",
              id: period?.id,
              name: period?.name,
            };
          }
        })
      : editData
      ? // eslint-disable-next-line
        periodicity.filter((period) => {
          let periodId = editData && editData?.attributes["periodicity-period"];
          if (periodId === period.id) {
            return {
              type: "period",
              id: period?.id,
              name: period?.name,
            };
          }
        })
      : "";

  let processId =
    Object.keys(formDataSet).length !== 0 && formDataSet?.data
      ? formDataSet?.data?.attributes["parent_process_id"]
      : editData
      ? editData?.attributes["parent-process-id"]
      : null;

  let processVal =
    processAll &&
    processAll?.filter(
      (data) => data?.id?.toString() === processId?.toString()
    )[0];

  const tempProcess = [
    {
      type: "process",
      name: processVal?.attributes.title,
      attributes: {
        process_id: processVal?.id?.toString(),
      },
      id: processVal?.id?.toString(),
    },
  ];

  const [processOwner, setProcessOwner] = useState(tempOwners);
  const [processDeputy, setProcessDeputy] = useState(tempDeputy);
  const [process, setProcess] = useState(tempProcess);
  const [uData, setUData] = useState(tempDate);
  const [timePeriod, setTimePeriod] = useState(tempTime);
  const [periodData, setPeriodData] = useState(tempPeriod);
  const [trainingValue, setTrainingValue] = useState(tempTraining);
  const [communityValue, setCommunityValue] = useState(tempCommunity);
  const [applicationValue, setApplicationValue] = useState(tempApplication);
  const [dept, setDept] = useState(tempDepartment);

  const processOptions = processAll
    ?.filter((item) => item?.id?.toString() !== editData?.id?.toString())
    ?.map((val) => {
      return {
        type: "process",
        name: val?.attributes?.title,
        attributes: {
          process_id: val?.id?.toString(),
        },
        id: null,
      };
    });

  const handlePeriod = (value) => {
    setPeriodData(value);
  };

  // const handlePreview = () => {
  //   sessionStorage.setItem("formData", JSON.stringify(payLoad));
  // };

  // const handlePreviewClick = () => {
  //   if (checkPageLevel === "addProcess") {
  //     pageLevel("addPreview");
  //     handlePreview();
  //     setProcessViewData(payLoad);
  //     setAddUpdateData(payLoad);
  //   } else if (checkPageLevel === "editProcess") {
  //     pageLevel("editPreview");
  //     handlePreview();
  //     setProcessViewData(payLoad);
  //   }
  // };

  const handleClear = () => {
    sessionStorage.removeItem("formData");
  };

  const handleDisplayFileDetails = async () => {
    let size = inputRef.current?.files[0].size / 1000000;
    if (size <= 3) {
      setTemplateErr(false);
      let tempFile = inputRef.current?.files && inputRef.current?.files[0];
      const base64 = await getBase64(tempFile);
      // eslint-disable-next-line
      const tempPreview = base64.split(",")?.pop();
      template64.current = tempPreview;

      const imageHash = hash({ file: tempFile, timestamp: now() });
      const extension = tempFile.name.substring(tempFile.name.lastIndexOf("."));
      // eslint-disable-next-line
      const url = `/files/process-template-files/${imageHash}${extension}`;

      setFormData({
        ...formData,
        template_url: url,
      });
    } else {
      setTemplateErr(true);
    }
  };

  const handleProcess = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "process",
        name: item?.name,
        attributes: {
          process_id: item?.attributes["process_id"]?.toString(),
        },
        id: item.id <= 0 ? null : item?.id?.toString(),
      };
      finalValue.push(newElem);
      return null;
    });
    setProcess(finalValue);
  };

  const handleTraining = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "trainings",
        name: item?.name,
        attributes: {
          training_id: item?.attributes["training_id"]?.toString(),
        },
        id: item.id > 0 ? item?.id?.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });
    setTrainingValue(finalValue);
  };

  const handleCommunity = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "community",
        name: item?.name,
        attributes: {
          community_id: item?.attributes["community_id"]?.toString(),
        },
        id: item.id > 0 ? item?.id?.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });
    setCommunityValue(finalValue);
  };

  const handleApplication = (value) => {
    const finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "application",
        name: item?.name,
        attributes: {
          application_id: item?.attributes["application_id"]?.toString(),
        },
        id: item.id > 0 ? item.id?.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });
    setApplicationValue(finalValue);
  };

  const handleUData = (value) => {
    setUData(value);
  };

  const handleTimeChange = (value) => {
    setTimePeriod(value);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeputyModal = () => {
    setShowDeputyModal(false);
  };

  const handleDeputyModal = () => {
    setShowDeputyModal(true);
  };

  const payLoad = {
    data: {
      type: "process",
      attributes: {
        title: formData?.processName,
        desc: formData?.processDesc,
        template: template64.current,
        template_url: formData?.template_url,
        handbook_link_url:
          formData?.handbookLink !== undefined &&
          formData?.handbookLink?.length > 0
            ? formData?.handbookLink?.includes("https:") ||
              formData?.handbookLink?.includes("http:")
              ? formData?.handbookLink
              : `https://${formData?.handbookLink}`
            : null,

        wiki_link_url:
          formData?.wikiLink !== undefined && formData?.wikiLink?.length > 0
            ? formData?.wikiLink?.includes("https:") ||
              formData?.wikiLink?.includes("http:")
              ? formData?.wikiLink
              : `https://${formData?.wikiLink}`
            : null,
        parent_process_id: process
          ? process[0]?.attributes?.["process_id"]
          : "",
        periodicity_period: periodData ? periodData : "",
        process_day: uData !== undefined && uData.length > 0 && uData[0].name,
        process_time:
          timePeriod !== undefined &&
          timePeriod?.length > 0 &&
          timePeriod[0]?.name,
      },
      relationships: {
        owners: processOwner.concat(processDeputy),
        communities: communityValue,
        apps: applicationValue,
        orgs: dept,
        trainings: trainingValue,
      },
      id: editData && editData?.id,
      pageLevel: pageLevel,
      updateData: updateData,
      setLoading: setLoading,
    },
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) {
      setDescriptionError("Description limit exceeded: 1000 characters");
    } else {
      setDescriptionError("");
    }
  };

  const handleProcessPayLoad = async (payload) => {
    const formError = validateForm();
    if (
      Object.keys(formError).length > 0 ||
      descriptionError.length > 1000 ||
      processOwner?.length === 0 ||
      nameErr === true
    ) {
      setFormErrors(formError);
      setOwnerCheck("Required");
    } else {
      if (checkPageLevel === "addProcess") {
        setLoading(true);
        updateData(true);
        await addProcess(payload);
      } else {
        setLoading(true);
        await updateProcessData(payload);
        updateData(true);
        setDescriptionError("");
      }
    }
  };

  const handleOrgChange = (value) => {
    let finalValue = [];
    value.map((item) => {
      let newElem = {
        type: "orgs",
        name: item?.name,
        attributes: {
          org_id: item?.attributes["org_id"]?.toString(),
        },
        id: item?.id <= 0 ? null : item?.id?.toString(),
      };

      finalValue.push(newElem);
      return null;
    });
    setDept(finalValue);
  };

  const getProcessName = (name) => {
    // eslint-disable-next-line
    initialProcessData.map((element) => {
      if (
        element?.attributes.title.toLowerCase() === name?.trim().toLowerCase()
      ) {
        setNameErr(true);
      }
    });
  };

  return (
    <div className="add-and-edit-process">
      <div className="d-flex justify-content-start pt-2">
        <p className="main-title">{props.title}</p>
      </div>
      <div>
        <Form>
          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">Process Name</Form.Label>
              <div className="app-primary-input-field ">
                <Form.Control
                  required
                  type="text"
                  key="field1"
                  className="text-field"
                  value={formData?.processName}
                  placeholder={"Enter Process Name"}
                  name="processName"
                  onChange={(e) => (
                    // eslint-disable-next-line
                    setNameErr(false),
                    getProcessName(e.target.value),
                    handleChange(e),
                    !!formErrors ? setFormErrors(formData?.processName) : ""
                  )}
                  isInvalid={!!formErrors?.processName}
                />
              </div>
              {formErrors?.processName ? (
                <p className="m-0 text-danger">Required*</p>
              ) : nameErr === true ? (
                <p className="m-0 text-danger">Name already exists</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Process Department
              </Form.Label>
              <div className="app-secondary-dropdown">
                <Typeahead
                  multiple={true}
                  selected={dept}
                  labelKey="name"
                  placeholder="Search for Org Code"
                  id="typeAhead"
                  clearButton
                  searchable
                  emptyLabel={
                    <div>
                      {orgQuery?.length < 1
                        ? "Keep typing..."
                        : orgQuery?.length >= 1
                        ? "Loading..."
                        : orgQuery?.length >= 1
                        ? "No matches found!"
                        : "Loading..."}
                    </div>
                  }
                  onChange={handleOrgChange}
                  onInputChange={(e) => {
                    setOrgQuery(e.toUpperCase());
                    // handleOrgQueryChange(e);
                  }}
                  options={
                    organizationData &&
                    organizationData
                      ?.sort(
                        (a, b) =>
                          a?.name.split(" ")?.length -
                          1 -
                          (b?.name.split(" ")?.length - 1)
                      )
                      ?.map((org, index) => ({
                        type: org?.type,
                        name: org?.name,
                        attributes: {
                          org_id: org?.attributes["org_id"]?.toString(),
                        },
                        id: org?.id ? org?.id : index - index * 2,
                      }))
                  }
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="d-flex justify-content-between">
                <p className="mb-0">Process Owners</p>
                <Button
                  className="app-icon-primary-btn"
                  onClick={handleOpenModal}
                >
                  +
                </Button>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  onChange={handleOwnerChange}
                  placeholder={" Select Process Owners"}
                  value={
                    processOwner?.length <= 3
                      ? processOwner?.map((owner) => owner.name).join(", ")
                      : processOwner
                          ?.slice(0, 3)
                          .map((owner) => owner.name)
                          .join(", ") + ` + ${processOwner?.length - 3} more`
                  }
                />
              </div>
              {ownerCheck?.length > 0 && processOwner?.length === 0 && (
                <p className="m-0 text-danger">Required*</p>
              )}
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="d-flex justify-content-between">
                <p className="mb-0">Process Deputies</p>
                <Button
                  className="app-icon-primary-btn"
                  onClick={handleDeputyModal}
                >
                  +
                </Button>
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  disabled
                  type="input"
                  placeholder={"Select Process Deputies"}
                  value={
                    processDeputy?.length <= 3
                      ? processDeputy?.map((owner) => owner.name).join(", ")
                      : processDeputy
                          ?.slice(0, 3)
                          .map((owner) => owner.name)
                          .join(", ") + ` + ${processDeputy?.length - 3} more`
                  }
                />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              className="mb-4  min-vh-50"
              controlId="validationCustom02"
            >
              <Form.Label className="font-weight-bold">
                Process Description
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  required
                  placeholder={"Enter Process Description"}
                  as="textarea"
                  name="processDesc"
                  key="field2"
                  rows={7}
                  onChange={(e) => (
                    // eslint-disable-next-line
                    handleChange(e),
                    handleDescriptionChange(e),
                    !!formErrors ? setFormErrors(formData?.processDesc) : ""
                  )}
                  value={formData?.processDesc}
                  isInvalid={!!formErrors?.processDesc}
                />
              </div>
              {formErrors?.processDesc ? (
                <p className="m-0 text-danger">Required*</p>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Required*
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} className="mb-4 min-vh-50">
              <Form.Label className="font-weight-bold">
                Upload Process Template
              </Form.Label>

              <Form.Control
                className="text-field"
                placeholder="File Name"
                // title="File Name"
                type="file"
                ref={inputRef}
                onChange={handleDisplayFileDetails}
              />
              <>
                {editData?.attributes["template-url"] ? (
                  <p className="mb-0">
                    Preview File:{" "}
                    {`${editData?.attributes.title}${editData?.attributes[
                      "template-url"
                    ].substring(
                      editData?.attributes["template-url"].lastIndexOf(".")
                    )}`}
                  </p>
                ) : null}
              </>
              <>
                {templateErr ? (
                  <span className="d-flex mt-1">
                    <p className="text-danger">
                      File too large<sup>*</sup>
                    </p>
                    <p className="text-muted ms-1">(Should be less than 3MB)</p>
                  </span>
                ) : null}
              </>

              <Form.Label className="font-weight-bold pt-4">
                Process Periodicity
              </Form.Label>
              <div className="app-primary-dropdown">
                <Select
                  placeholder="Select Process Periodicity"
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="true"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  className="dropdown-select"
                  options={
                    periodicity &&
                    periodicity?.map((period) => ({
                      type: "period",
                      id: parseInt(period?.id),
                      name: period?.name,
                    }))
                  }
                  onChange={handlePeriod}
                  values={periodData?.length > 0 ? periodData : []}
                />
              </div>
              <Row className="pt-4">
                <Form.Group as={Col}>
                  <Form.Label>Select Date</Form.Label>
                  <div className="app-primary-dropdown">
                    <Select
                      placeholder="Select Ultimo Date"
                      Clearable
                      searchable="true"
                      disabledLabel="disabled"
                      create="false"
                      labelField="name"
                      valueField="id"
                      color="#8A00E5"
                      keepSelectedInList={true}
                      className="dropdown-select"
                      options={
                        dataU &&
                        dataU.map((period) => ({
                          id: period?.id,
                          name: period?.name,
                        }))
                      }
                      onChange={handleUData}
                      values={uData?.length > 0 ? uData : []}
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Select Time</Form.Label>
                  <div className="app-primary-dropdown">
                    <Select
                      placeholder="Select Time"
                      Clearable
                      searchable="true"
                      disabledLabel="disabled"
                      create="false"
                      labelField="name"
                      valueField="id"
                      color="#8A00E5"
                      keepSelectedInList={true}
                      className="dropdown-select"
                      options={dateSelect.map((date) => ({
                        name: date?.name,
                        id: date?.id,
                      }))}
                      onChange={handleTimeChange}
                      values={timePeriod.length > 0 ? timePeriod : []}
                    />
                  </div>
                </Form.Group>
              </Row>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Handbook Link
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  placeholder="Enter Handbook Link"
                  required
                  type="email"
                  name="handbookLink"
                  onChange={(e) => handleChange(e)}
                  value={formData?.handbookLink}
                />
              </div>
            </Form.Group>
            <Form.Group
              as={Col}
              className=" mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Wikisphere Link
              </Form.Label>
              <div className="app-primary-input-field">
                <Form.Control
                  placeholder="Enter Wikisphere Link"
                  required
                  type="email"
                  name="wikiLink"
                  onChange={(e) => handleChange(e)}
                  value={formData?.wikiLink}
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-4">
              <Form.Label className="font-weight-bold">
                Parent Process
              </Form.Label>
              <div className="app-primary-dropdown">
                <Select
                  placeholder={"Select Parent Process"}
                  Clearable
                  searchable="true"
                  disabledLabel="disabled"
                  create="false"
                  labelField="name"
                  valueField="id"
                  color="#8A00E5"
                  keepSelectedInList={true}
                  className="dropdown-select"
                  options={processOptions
                    ?.sort((a, b) => a?.name.localeCompare(b?.name))
                    ?.map((process, index) => ({
                      type: "process",
                      name: process?.name,
                      attributes: {
                        process_id:
                          process?.attributes["process_id"]?.toString(),
                      },
                      id: process?.id ? process?.id : index - index * 2,
                    }))}
                  onChange={handleProcess}
                  values={process?.[0]?.id !== undefined ? process : []}
                />
              </div>
            </Form.Group>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Applications Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder={"Select Applications"}
                  multi={true}
                  options={
                    applicationData &&
                    applicationData
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((application, index) => ({
                        type: "application",
                        name: application.name,
                        attributes: {
                          application_id:
                            application.attributes[
                              "application_id"
                            ]?.toString(),
                        },
                        id: application?.id
                          ? application?.id?.toString()
                          : index - index * 2,
                      }))
                  }
                  values={applicationValue}
                  onChange={handleApplication}
                />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Community Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder={"Select Communities"}
                  multi={true}
                  options={
                    communityData &&
                    communityData
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((community, index) => ({
                        type: "community",
                        name: community?.name,
                        attributes: {
                          community_id:
                            community &&
                            community?.attributes["community_id"]?.toString(),
                        },
                        id: community?.id
                          ? community?.id?.toString()
                          : index - index * 2,
                      }))
                  }
                  values={communityValue}
                  onChange={handleCommunity}
                />
              </div>
            </Form.Group>

            <Form.Group
              as={Col}
              className="mb-4"
              controlId="validationCustom01"
            >
              <Form.Label className="font-weight-bold">
                Trainings Connections
              </Form.Label>
              <div className="app-primary-dropdown">
                <MultipleSelectDropdown
                  placeholder={"Select Trainings"}
                  multi={true}
                  options={
                    trainingData &&
                    trainingData
                      ?.sort((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((training, index) => ({
                        type: "trainings",
                        name: training?.name,
                        attributes: {
                          training_id:
                            training?.attributes["training_id"]?.toString(),
                        },
                        id: training?.id
                          ? training?.id?.toString()
                          : index - index * 2,
                      }))
                  }
                  values={trainingValue}
                  onChange={handleTraining}
                />
              </div>
            </Form.Group>
          </Row>
          {showModal === true ? (
            <EmployeeModal
              show={showModal}
              handleClose={handleCloseModal}
              type={"owners"}
              getMembers={processOwner}
              getExisting={processDeputy}
              title={"Add Owners To Process"}
              setMember={setProcessOwner}
              buttonTitle={"Add Owners"}
              role={"is-owners"}
              is_owners={true}
            />
          ) : (
            ""
          )}
          {showDeputyModal === true ? (
            <EmployeeModal
              show={showDeputyModal}
              type={"owners"}
              handleClose={handleCloseDeputyModal}
              getMembers={processDeputy}
              getExisting={processOwner}
              title={"Add Deputy To Process"}
              setMember={setProcessDeputy}
              buttonTitle={"Add Deputies"}
              role={"is-owners"}
              is_owners={false}
            />
          ) : (
            ""
          )}
        </Form>
        <div className="d-flex justify-content-end font-weight-bold pt-4">
          <Button
            className="app-secondary-btn"
            onClick={() => {
              pageLevel("process");
              handleClear();
            }}
          >
            Cancel
          </Button>

          <Button
            className="app-primary-btn ms-3"
            onClick={() => handleProcessPayLoad(payLoad)}
          >
            {" "}
            {checkPageLevel === "addProcess"
              ? loading === true
                ? "Creating Process..."
                : "Create Process"
              : loading === true
              ? "Updating Process..."
              : "Update Process"}{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}
