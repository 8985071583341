import { postData } from "../services/apiService";
import { getHostUrl, getBaseUrl } from "../config/environment";
import { patchData } from "../services/apiService";
import { deleteData } from "../services/apiService";
import { getBase64 } from "./favoriteUtils";
import { now } from "lodash";
import { Modal, Button } from "react-bootstrap";
import { ListGroupItem } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import Avatar from "react-avatar";
import ImageSvgs from "../components/ImageSvgs";
import "../components/ManageAdminPage/ManageTools.scss";
import { useState } from "react";
import { useNavigate } from "react-router";

export const HandleToolSubmit = async (props) => {
  const toolVal = props;
  const relationships = toolVal.data.attributes;
  const imageFile = toolVal.data["attributes"].tool_image;

  let base64 = null;

  let sendImageUrl = null;
  const hash = require("object-hash");

  const communityId = relationships.communities.map((comId) => ({
    type: "community",
    attributes: {
      community_id: comId.attributes["community_id"].toString(),
    },
    id: null,
  }));

  const trainingId = relationships.trainings.map((triId) => ({
    type: "training",
    attributes: {
      training_id: triId.attributes["training_id"],
    },
    id: null,
  }));

  if (imageFile != null) {
    const base64Img = await getBase64(imageFile);
    // eslint-disable-next-line
    base64 = base64Img.split(",")?.pop();
    const imageHash = hash({ file: imageFile, timestamp: now() });
    const extension = imageFile.type?.split("/")?.pop();
    // eslint-disable-next-line
    sendImageUrl = `/images/tools/${imageHash}.${extension}`;
  }

  const finalToolData = {
    data: {
      type: "tool",
      attributes: {
        image: base64,
        tool_image: sendImageUrl,
        tool_description: toolVal.data.attributes["tool_description"],
        tool_title: toolVal.data.attributes["tool_title"],
        tool_name: toolVal.data.attributes["tool_name"],
        experts: { data: relationships.experts },
        communities: { data: communityId },
        trainings: { data: trainingId },
      },
      relationships: {
        "tool-info": { data: props.data.attributes.additionalInfo },
      },
    },
  };

  const url = `${getHostUrl()}tools`;
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
  };
  try {
    // eslint-disable-next-line
    const toolRes = await postData(url, finalToolData, headers);
    if (toolRes.status === 200) {
      props?.updateData(true);
      props?.navigateFun("tool");
      props?.setLoading(false);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateTool = async (props) => {
  const toolData = props["payLoad"];
  const relationships = props["payLoad"].data.attributes;
  const imageData = props["payLoad"].data.attributes.tool_image;
  let base64 = null;
  let sendImageUrl = null;
  const hash = require("object-hash");

  if (imageData !== null && typeof imageData === "string") {
    // eslint-disable-next-line
    sendImageUrl = imageData;
    base64 = null;
  } else if (imageData !== null && typeof imageData === "object") {
    const base64Img = await getBase64(imageData);
    base64 = base64Img.split(",")?.pop();
    const imageHash = hash({ file: imageData, timestamp: now() });
    const extension = imageData.type?.split("/")?.pop();
    sendImageUrl = `/images/tools/${imageHash}.${extension}`;
  } else {
    base64 = null;
    sendImageUrl = null;
  }

  const updateData = {
    data: {
      type: "tool",
      attributes: {
        image: base64,
        tool_image: imageData ? sendImageUrl : null,
        tool_description: toolData["data"].attributes.tool_description,
        tool_title: toolData["data"].attributes.tool_title,
        tool_name: toolData["data"].attributes.tool_name,
        experts: {
          data: relationships?.experts.length > 0 ? relationships?.experts : [],
        },
        communities: {
          data:
            relationships?.communities.length > 0
              ? relationships?.communities
              : [],
        },
        trainings: {
          data:
            relationships?.trainings.length > 0 ? relationships?.trainings : [],
        },
      },
      relationships: {
        "tool-info": { data: toolData?.data.attributes.additionalInfo },
      },
    },
  };

  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
  };

  try {
    // eslint-disable-next-line
    const res = await patchData(
      `${getHostUrl()}tools/${props["id"]}`,
      updateData,
      headers
    );
    // eslint-disable-next-line
    if (res.status === 200) {
      props?.updateData(true);
      props?.navigateFun("tool");
      props?.setLoading(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteToolModal = ({ setFlag, ...props }) => {
  const deleteTool = async () => {
    const deleteToolId = props.deletetool.id;
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    };
    // eslint-disable-next-line
    const res = await deleteData(
      `${getHostUrl()}tools/${deleteToolId}`,
      headers
    );
    if (res.status === 204) {
      props.onHide();
      setFlag(true);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="pt-1 text-capitalize">
          <strong>Tool Name : {props.name} </strong>
          <br></br>
        </span>
        <span className="pt-1">{props.modalmessage}</span>
      </Modal.Body>
      <Modal.Footer>
        {props.deletetool.relationships.apps.data.length === 0 ? (
          <Button
            id="common-favbutton"
            onClick={() => {
              deleteTool();
            }}
          >
            Delete
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const UserModal = (props) => {
  const [copy, setCopy] = useState(false);
  const [copyMail, setCopyMail] = useState(false);
  const [id, setId] = useState(null);
  const listData = props.listData;
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="user-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="user-modal-title"
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.heading}
        <ListGroup>
          {listData.map((value) => {
            let imageUrl =
              value.attributes.employee.data.attributes["profile-picture"];

            return (
              <ListGroupItem className="user-modal-user-list">
                <span className="d-flex justify-content-between">
                  <span>
                    <span>
                      {imageUrl ? (
                        <img
                          className="my-auto"
                          src={`${getBaseUrl()}${imageUrl}`}
                          style={{
                            height: "2rem",
                            width: "2rem",
                            border: "1.5px solid black",
                            borderRadius: "7rem",
                            objectFit: "contain",
                          }}
                          alt="User Img"
                        />
                      ) : (
                        <Avatar
                          name={
                            value.attributes.employee &&
                            value.attributes.employee.data.attributes[
                              "preferred-name"
                            ]
                          }
                          size="32"
                          round={true}
                        />
                      )}
                    </span>
                    <span className="px-2 user-name">
                      {" "}
                      {value.attributes.employee &&
                        value.attributes.employee.data.attributes[
                          "preferred-name"
                        ]}{" "}
                    </span>
                  </span>

                  <span className="d-flex justify-content-between">
                    <span>
                      <button
                        className=" bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copy ? "Copy email" : "Copied"}
                        disabled={copy}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes.employee.data.attributes[
                              "email-id"
                            ]
                          );
                          setCopy(true);
                          setId(value.id);
                          setTimeout(() => {
                            setCopy(false);
                            setId(null);
                          }, 1500);
                        }}
                      >
                        {id !== value.id ? (
                          <>
                            <ImageSvgs name="expertCopy" />
                          </>
                        ) : (
                          <>
                            <ImageSvgs name="tickMark" />
                          </>
                        )}
                      </button>
                    </span>
                    <span>
                      <button
                        className="ms-2 bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copyMail ? "Mail to" : "Mail to:"}
                        disabled={copyMail}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes.employee.data.attributes[
                              "email-id"
                            ]
                          );
                          setCopyMail(true);
                        }}
                      >
                        {value.id && (
                          <>
                            <a
                              href={`mailto:${value.attributes.employee.data.attributes["email-id"]}`}
                            >
                              <ImageSvgs name="expertMail" />
                            </a>
                          </>
                        )}
                      </button>
                    </span>

                    <span>
                      <button
                        className="bg-transparent border-0"
                        onClick={() => {
                          navigate(
                            `/employee_relation?id=${value.attributes["employee-id"]}`
                          );
                        }}
                      >
                        <ImageSvgs name="relationIcon" />
                      </button>
                    </span>
                  </span>
                </span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
