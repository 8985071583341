import { useState, useEffect, React } from "react";
import { Table } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { ProcessDeleteModal } from "../../utils/processUtil";
import Avatar from "react-avatar";
import { UserModal } from "../../utils/processUtil";
import { useNavigate } from "react-router";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import { getBaseUrl } from "../../config/environment";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import Loader from "../Loader/Loader";
import "../../assets/scss/common.scss";
import "./ProcessAdminPage.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

export default function ProcessTable(props) {
  const orgOptions = props?.organizationData?.data.map((org) => ({
    name: org.attributes["org-title"],
    attributes: org.attributes,
    id: org.id,
  }));

  const periodOptions = [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Quarterly" },
    { id: 3, name: "Annually" },
  ];

  const updateData = props.setFlag;
  const initialData = props.processData;
  const [processTableData, setProcessTableData] = useState(props.processData);
  const pageLevel = props.setPageLevel;
  const queryParam = props.addQueryParam;
  const editDataSet = props.setProcessEditData;
  const viewData = props.setProcessViewData;
  const [showModal, setShowModal] = useState(false);
  const [deleteProcessData, setDeleteProcessData] = useState("");
  const [userModal, setShowUserModal] = useState(false);
  const [usersData, setUsersData] = useState();
  const navigate = useNavigate();
  const [res, setRes] = useState(false);
  const [includedData, setInculdedData] = useState();
  const [nameQuery, setNameQuery] = useState("");
  const [descQuery, setDescQuery] = useState("");
  const [orgQuery, setOrgQuery] = useState([]);
  const [periodQuery, setPeriodQuery] = useState([]);

  useEffect(() => {
    const param1 = `${getHostUrl()}process?include=owners,trainings,communities,apps,orgs&use_pagination=false`;
    (async () => {
      setRes(true);
      const res1 = await getData(param1);
      await setProcessTableData(res1.data.data);
      setInculdedData(res1.data.included);
    })();

    // eslint-disable-next-line
  }, []);

  const setUsers = (list) => {
    let userList = [];
    // eslint-disable-next-line
    list.map((e) => {
      let newElem = includedData?.filter(
        (item) =>
          item.type === "owners" && item.id.toString() === e.id.toString()
      )[0];
      userList.push(newElem);
    });
    return userList;
  };

  const nameSearch = (query) => {
    if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(initialData);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.title.toLowerCase().includes(query)
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.desc.toLowerCase().includes(descQuery?.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter(
          (item) =>
            item.attributes.title.toLowerCase().includes(query) &&
            item.attributes.desc.toLowerCase().includes(descQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.desc
              .toLowerCase()
              .includes(descQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setProcessTableData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes.title === elem2.attributes.title
          )
        )
      );
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title.toLowerCase().includes(query)
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title.toLowerCase().includes(query)
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.desc
              .toLowerCase()
              .includes(descQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title.toLowerCase().includes(query) &&
              item.attributes.desc
                .toLowerCase()
                .includes(descQuery.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) =>
          item.attributes.desc.toLowerCase().includes(descQuery?.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) => item.attributes.title.toLowerCase().includes(query))
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title.toLowerCase().includes(query) &&
              item.attributes.desc
                .toLowerCase()
                .includes(descQuery.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter(
          (item) =>
            item.attributes.title.toLowerCase().includes(query) &&
            item.attributes.desc.toLowerCase().includes(descQuery.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    }
  };

  const descSearch = (query) => {
    if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(initialData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.title.toLowerCase().includes(nameQuery?.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.desc.toLowerCase().includes(query)
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter(
          (item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase()) &&
            item.attributes.desc.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter((item) => item.attributes.desc.toLowerCase().includes(query))
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setProcessTableData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes.title === elem2.attributes.title
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) => item.attributes.desc.toLowerCase().includes(query))
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title
                .toLowerCase()
                .includes(nameQuery?.toLowerCase()) &&
              item.attributes.desc.toLowerCase().includes(query.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) => item.attributes.desc.toLowerCase().includes(query))
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) =>
          item.attributes.title.toLowerCase().includes(nameQuery?.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title
                .toLowerCase()
                .includes(nameQuery?.toLowerCase()) &&
              item.attributes.desc.toLowerCase().includes(query.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter(
          (item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase()) &&
            item.attributes.desc.toLowerCase().includes(query.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    }
  };

  const orgSearch = (value) => {
    setOrgQuery(value);
    if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(initialData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.title.toLowerCase().includes(nameQuery?.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.desc.toLowerCase().includes(descQuery?.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter(
          (item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase()) &&
            item.attributes.desc.toLowerCase().includes(descQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.desc
              .toLowerCase()
              .includes(descQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setProcessTableData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes.title === elem2.attributes.title
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList.push(i);
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.desc
              .toLowerCase()
              .includes(descQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title
                .toLowerCase()
                .includes(nameQuery?.toLowerCase()) &&
              item.attributes.desc
                .toLowerCase()
                .includes(descQuery.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) =>
          item.attributes.desc.toLowerCase().includes(descQuery?.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) =>
          item.attributes.title.toLowerCase().includes(nameQuery?.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title
                .toLowerCase()
                .includes(nameQuery?.toLowerCase()) &&
              item.attributes.desc
                .toLowerCase()
                .includes(descQuery.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0" &&
      periodQuery?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(value[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          periodQuery[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter(
          (item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase()) &&
            item.attributes.desc.toLowerCase().includes(descQuery.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    }
  };

  const periodSearch = (value) => {
    setPeriodQuery(value);
    if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() === "0"
    ) {
      setProcessTableData(initialData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.title.toLowerCase().includes(nameQuery?.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter((item) =>
          item.attributes.desc.toLowerCase().includes(descQuery?.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() === "0"
    ) {
      setProcessTableData(
        initialData?.filter(
          (item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase()) &&
            item.attributes.desc.toLowerCase().includes(descQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.desc
              .toLowerCase()
              .includes(descQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setProcessTableData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes.title === elem2.attributes.title
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });

      setProcessTableData(
        initialData
          ?.filter((item) =>
            item.attributes.desc
              .toLowerCase()
              .includes(descQuery?.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() === "0"
    ) {
      let tempList = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList.push(i);
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title
                .toLowerCase()
                .includes(nameQuery?.toLowerCase()) &&
              item.attributes.desc
                .toLowerCase()
                .includes(descQuery.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) =>
          item.attributes.desc.toLowerCase().includes(descQuery?.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter((item) =>
          item.attributes.title.toLowerCase().includes(nameQuery?.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() === "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setProcessTableData(
        initialData
          ?.filter(
            (item) =>
              item.attributes.title
                .toLowerCase()
                .includes(nameQuery?.toLowerCase()) &&
              item.attributes.desc
                .toLowerCase()
                .includes(descQuery.toLowerCase())
          )
          .filter((elem1) =>
            tempList.some(
              (elem2) =>
                elem1.id === elem2.id &&
                elem1.attributes.title === elem2.attributes.title
            )
          )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      orgQuery?.length.toString() !== "0" &&
      value?.length.toString() !== "0"
    ) {
      let tempList1 = [];
      initialData?.map((i) => {
        let tempOrgList = i?.relationships.orgs?.data.map((elem) => {
          return includedData?.filter(
            (item) =>
              item.type === "orgs" && item.id.toString() === elem.id.toString()
          )[0]?.attributes["org-master"].data.id;
        });
        if (tempOrgList.length > 0 && tempOrgList.includes(orgQuery[0]?.id))
          tempList1.push(i);
        return false;
      });

      let tempList2 = [];
      initialData?.map((elem) => {
        if (
          elem.attributes["periodicity-period"] &&
          value[0].id.toString() ===
            elem.attributes["periodicity-period"].toString()
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      let arr1 = initialData
        ?.filter(
          (item) =>
            item.attributes.title
              .toLowerCase()
              .includes(nameQuery?.toLowerCase()) &&
            item.attributes.desc.toLowerCase().includes(descQuery.toLowerCase())
        )
        .concat(tempList1, tempList2);

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(initialData?.filter((item) => item.id === elem.id)[0]);
        }
        return false;
      });

      setProcessTableData(finalList);
    }
  };

  return (
    <div>
      <div className="table-responsive">
        <Table className="main-table" striped>
          <thead className="user-table-head">
            <tr>
              <th>Process Name </th>
              <th>Process Description</th>
              <th>Process Periodicity </th>
              <th>Process Owners</th>
              <th>Department</th>
              <th>Action</th>
            </tr>
            <tr style={{ height: "3.5rem" }}>
              <th>
                <div className="app-primary-input-field mb-2">
                  <input
                    className="me-3 w-100"
                    type="search"
                    placeholder=" Search by name..."
                    value={nameQuery.current}
                    onChange={(e) => {
                      setNameQuery(e.target.value);
                      nameSearch(e.target.value.toLowerCase());
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field  mb-2">
                  <input
                    className="me-3 w-100"
                    type="search"
                    placeholder=" Search by description..."
                    value={descQuery.current}
                    onChange={(e) => {
                      setDescQuery(e.target.value);
                      descSearch(e.target.value.toLowerCase());
                    }}
                  />
                </div>
              </th>
              <th>
                <div
                  className="app-primary-dropdown mb-2"
                  style={{ position: "absolute", width: "10%" }}
                >
                  <Typeahead
                    multiple={false}
                    selected={periodQuery}
                    labelKey="name"
                    emptyLabel=""
                    placeholder="Search by periodicity..."
                    id="typeAhead"
                    clearButton
                    searchable
                    onChange={periodSearch}
                    options={periodOptions}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field  mb-2">
                  <input
                    disabled
                    className="me-3 w-100"
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
              <th>
                <div
                  className="app-primary-dropdown mb-2"
                  style={{ position: "absolute", width: "28%" }}
                >
                  <Typeahead
                    multiple={false}
                    selected={orgQuery}
                    labelKey="name"
                    emptyLabel=""
                    placeholder="Search by department..."
                    id="typeAhead"
                    clearButton
                    searchable
                    onChange={orgSearch}
                    options={orgOptions}
                  />
                </div>
              </th>
              <th>
                <div className="search-div mb-2">
                  <input
                    disabled
                    className="search-bar no-bg disabled-search-bar me-3 w-100"
                    style={{
                      height: "32px !important",
                      width: "95% !important",
                    }}
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
            </tr>
          </thead>
          {res ? (
            <tbody>
              {processTableData && processTableData.length > 0 ? (
                <>
                  {processTableData &&
                    processTableData
                      ?.sort((a, b) =>
                        a.attributes["title"].localeCompare(
                          b.attributes["title"]
                        )
                      )
                      ?.map((data) => (
                        <tr key={data.id}>
                          <td className="app-table-name">
                            <span
                              className="me-2 app-table-name"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Visit Process"
                              onClick={() => {
                                viewData(data);
                              }}
                            >
                              <Link
                                className="app-table-name"
                                style={{ textDecoration: "none" }}
                                to={`/landscape/process?process_id=${data.id}`}
                                state={{ prevPath: window.location.href }}
                              >
                                {data.attributes.title}
                              </Link>
                            </span>
                          </td>
                          <td>{data.attributes.desc}</td>
                          <td>
                            {data?.attributes["periodicity-period"] === 1
                              ? "Monthly"
                              : data?.attributes["periodicity-period"] === 2
                              ? "Quarterly"
                              : data?.attributes["periodicity-period"] === 3
                              ? "Annually"
                              : "-"}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <span>
                              {data?.relationships?.owners?.data?.length > 0 ? (
                                <>
                                  {data?.relationships?.owners?.data
                                    .slice(0, 3)
                                    .map((elem, index) => {
                                      let owner = includedData?.filter(
                                        (item) =>
                                          item.type === "owners" &&
                                          item.id.toString() ===
                                            elem.id.toString()
                                      )[0];

                                      return (
                                        <span
                                          className="me-1"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(
                                              `/employee_relation?id=${owner?.attributes["employee-id"]}`
                                            )
                                          }
                                        >
                                          {owner?.attributes?.employee?.data
                                            ?.attributes["profile-picture"] ? (
                                            <img
                                              className="my-auto"
                                              src={`${getBaseUrl()}${
                                                owner?.attributes?.employee
                                                  ?.data?.attributes[
                                                  "profile-picture"
                                                ]
                                              }`}
                                              style={{
                                                height: "2rem",
                                                width: "2rem",
                                                border: "1.5px solid black",
                                                borderRadius: "7rem",
                                                objectFit: "contain",
                                              }}
                                              alt="User Img"
                                            />
                                          ) : (
                                            <Avatar
                                              name={
                                                owner?.attributes?.employee
                                                  ?.data?.attributes[
                                                  "preferred-name"
                                                ]
                                              }
                                              size="30"
                                              round={true}
                                            />
                                          )}
                                        </span>
                                      );
                                    })}
                                  {data?.relationships?.owners?.data.length >
                                  3 ? (
                                    <>
                                      <button
                                        className="more-user-btn"
                                        onClick={async () => {
                                          let userList = await setUsers(
                                            data?.relationships?.owners?.data
                                          );

                                          setUsersData(userList);
                                          setShowUserModal(true);
                                        }}
                                      >
                                        +{" "}
                                        {data?.relationships?.owners?.data
                                          .length - 3}{" "}
                                        more
                                      </button>
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <b>-</b>
                              )}
                            </span>
                          </td>
                          <td>
                            {" "}
                            {data?.relationships?.orgs?.data.length > 0 ? (
                              <>
                                {data?.relationships?.orgs?.data.map(
                                  (elem, index) => {
                                    let org = includedData?.filter(
                                      (item) =>
                                        item.type === "orgs" &&
                                        item.id.toString() ===
                                          elem.id.toString()
                                    )[0]?.attributes["org-master"].data;

                                    if (
                                      index >=
                                      data?.relationships?.orgs?.data.length - 1
                                    ) {
                                      return (
                                        <span>
                                          {org?.attributes["org-title"]}
                                        </span>
                                      );
                                    } else {
                                      return (
                                        <span>
                                          {org?.attributes["org-title"]} ,{" "}
                                        </span>
                                      );
                                    }
                                  }
                                )}
                              </>
                            ) : (
                              <b>-</b>
                            )}
                          </td>
                          <td>
                            <span className="d-flex flex-row">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  pageLevel("editProcess");
                                  queryParam("page", "editProcess");
                                  editDataSet(data);
                                }}
                              >
                                <ImageSvgs name="edit" />
                              </span>
                              <span
                                className="px-4 cursor-pointer"
                                onClick={() => {
                                  setShowModal(true);
                                  setDeleteProcessData(data);
                                }}
                              >
                                <ImageSvgs name="delete" />
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))}
                </>
              ) : (
                <tr className="w-100">
                  <td
                    className="w-100 mt-2"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#e9ecef ",
                    }}
                  >
                    <p className="fw-bold mt-1 mb-0">
                      No matching records found.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          ) : (
            <div style={{ maxWidth: "100%", height: "30rem" }}>
              <Loader />
            </div>
          )}
        </Table>

        {showModal === true ? (
          <ProcessDeleteModal
            deleteProcessData={deleteProcessData}
            show={showModal}
            onHide={() => setShowModal(false)}
            updateData={updateData}
          />
        ) : (
          ""
        )}
        {userModal === true ? (
          <UserModal
            title={"Process Owners"}
            heading={"List of Process Owners"}
            listData={usersData}
            show={userModal}
            onHide={() => setShowUserModal(false)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
