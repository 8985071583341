import React from "react";
import { useEffect, useState } from "react";
import { getBaseUrl } from "../config/environment";
import { getData } from "../services/apiService";
import BusinessControlling from "../components/OrgChart/OrgChartComp";
import IconExpansionTreeView from "../components/OrgChart/OrganizationUserScreen";

import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";

const OrganizationPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let flag = window.location.href.includes("v2");
  // eslint-disable-next-line
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getData(
        `${getBaseUrl()}/configurations/organizationMap/organizationPage.json`
      );
      await setData(res.data);
    })();
  }, []);

  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <div>
            <h2 className="fw-bold">Organizations</h2>
          </div>
          <div>
            <Button
              className="app-tertiary-btn ms-3 mt-1"
              onClick={() => {
                if (state?.prevPath) {
                  window.location.assign(state?.prevPath);
                } else {
                  navigate("/");
                }
              }}
            >
              Close page X
            </Button>
          </div>
        </div>

        <div className="mt-3">
          {flag ? (
            <BusinessControlling source={data} />
          ) : (
            <IconExpansionTreeView />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationPage;
