import { React, useState, useEffect } from "react";
import ExpertsComponent from "./ExpertsComponent";
import { Carousel, Stack } from "react-bootstrap";
import { nextArrow, prevArrow } from "../../utils/iconUtils";

export default function CarouselExpertComponent(data, includedData, owner) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (data && data?.data?.length > 0) {
      setActiveIndex(0);
    }
  }, [data]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="w-100">
      {data?.data?.length > 0 && (
        <Carousel
          nextIcon={nextArrow}
          prevIcon={prevArrow}
          interval={null}
          wrap={false}
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {data?.data?.map((record, i) => (
            <Carousel.Item key={i}>
              <Stack
                direction="horizontal"
                className="h-100 align-items-center"
                gap={2}
              >
                {record.map((elem, j) => {
                  if (j < record.length - 1) {
                    return (
                      <>
                        <ExpertsComponent
                          key={j}
                          data={elem}
                          owner={
                            includedData?.length > 0
                              ? includedData?.filter(
                                  // eslint-disable-next-line
                                  (owners) =>
                                    owners?.type === "owners" &&
                                    owners?.attributes[
                                      "employee-id"
                                    ]?.toString() === elem?.id?.toString()
                                )[0]?.attributes["is-owners"]
                              : {
                                  owner,
                                }
                          }
                        />
                        <div className="expert-divider"></div>
                      </>
                    );
                  } else {
                    return (
                      <ExpertsComponent
                        key={j}
                        data={elem}
                        owner={
                          includedData?.length > 0
                            ? includedData?.filter(
                                // eslint-disable-next-line
                                (owners) =>
                                  owners?.type === "owners" &&
                                  owners?.attributes[
                                    "employee-id"
                                  ]?.toString() === elem?.id?.toString()
                              )[0]?.attributes["is-owners"]
                            : {
                                owner,
                              }
                        }
                      />
                    );
                  }
                })}
              </Stack>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
}
