import React from "react";
import ToolMap from "../components/ApplicationMap/index";
import MainAppScreen from "../components/ApplicationMap/MainAppScreen";
import { useEffect, useState } from "react";
import { getBaseUrl, getHostUrl } from "../config/environment";
import { getData } from "../services/apiService";
import Loader from "../components/Loader/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";

const ApplicationsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let flag = window.location.href.includes("v2");
  const [appData, setAppData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [data, setData] = useState(null);
  const [v2Data, setv2Data] = useState(null);
  useEffect(() => {
    (async () => {
      const v2res = await getData(
        `${getBaseUrl()}/configurations/applicationMap/applicationsPage.json`
      );
      await setv2Data(v2res.data);

      const appRes = await getData(
        `${getHostUrl()}application?include=app_info,owners,communities,process,trainings,tools&use_pagination=false`
      );

      await setAppData(appRes.data);
      const orgRes = await getData(
        `${getBaseUrl()}/configurations/orgLookup.json`
      );
      await setOrgData(orgRes.data.orgs);
      const res = await getData(
        `${getBaseUrl()}/configurations/applicationMap/applicationsPage.json`
      );
      await setData(res.data);
    })();
  }, []);

  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <div className="page-header d-flex flex-row justify-content-between">
          <div>
            <h2 className="fw-bold">Applications</h2>
          </div>
          <div>
            <Button
              className="app-tertiary-btn ms-3 mt-1"
              onClick={() => {
                if (state?.prevPath) {
                  window.location.assign(state?.prevPath);
                } else {
                  navigate("/");
                }
              }}
            >
              Close page X
            </Button>
          </div>
        </div>
        {data ? (
          <div className="page-content">
            {flag ? (
              <ToolMap source={v2Data} />
            ) : (
              <MainAppScreen source={{ appData: appData, orgData: orgData }} />
            )}
          </div>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationsPage;
