import React, { useEffect } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import "./style.scss";

const LayoutFlow = ({ appdata }) => {
  const appConfig = appdata?.attributes["app-config"];

  //  eslint-disable-next-line
  const reactFlowInstance = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(appConfig?.edges);

  useEffect(() => {
    (async () => {
      appConfig?.nodes.forEach((elem) => {
        const newNode = {
          id: elem.id,
          position: elem.position,
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: (
              <div className="node-box">
                <div
                  className="node-up"
                  style={{
                    backgroundColor:
                      elem.data.layerId.id === 1
                        ? "#8C7500"
                        : elem.data.layerId.id === 2
                        ? "#006C3B"
                        : "#00328C",
                    borderRadius: "3px",
                  }}
                >
                  <div className="layerText">{elem.data.layerId.name}</div>
                  <div
                    className="nameText"
                    title={elem.data.toolDetails.attributes["tool-name"]}
                  >
                    {elem.data.toolDetails.attributes["tool-name"]}
                  </div>
                </div>
                <div className="node-down">
                  <div className="descText" title={elem.data.description}>
                    {elem.data.description}
                  </div>
                </div>
              </div>
            ),
          },
        };
        setNodes((nds) => nds.concat(newNode));
      });
      setEdges(appConfig?.edges);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="layoutflow" style={{ height: "485px" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        deleteKeyCode=""
        draggable={false}
        nodesDraggable={false}
        fitView
        preventScrolling={true}
        zoomOnScroll={false}
        panOnDrag={false}
        //onScrollCapture={false}
        panOnScroll={false}
      />
      <Controls />
    </div>
  );
};

function FlowWithProvider(props) {
  return (
    <ReactFlowProvider>
      <LayoutFlow {...props} />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
