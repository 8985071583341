import { useState, useEffect } from "react";
import "../assets/scss/common.scss";
import ImageSvgs from "../components/ImageSvgs";
import { getData } from "../services/apiService";
import { getHostUrl } from "../config/environment";
import { useNavigate } from "react-router-dom";
import ProcessTable from "../components/ProcessAdminComponent/ProcessTable";
import "../components/ProcessAdminComponent/ProcessAdminPage.scss";
import AddAndEditProcess from "../components/ProcessAdminComponent/AddAndEditProcess";
import TopicTable from "../components/TopicsAdminComponent/TopicTable";
import AddTopic from "../components/TopicsAdminComponent/AddTopic";
import ViewTopic from "../components/TopicsAdminComponent/ViewTopic";
import PreviewProcess from "../components/ProcessAdminComponent/PreviewProcess";
import { ButtonGroup, Button } from "react-bootstrap";
import Loader from "../components/Loader/Loader";

export default function ManageProcess() {
  const [processData, setProcessData] = useState(null);
  const [topicData, setTopicData] = useState(null);
  const [res, setRes] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);
  const url = new URL(window.location.href);
  const navigate = useNavigate();
  const [pageLevel, setPageLevel] = useState(url.searchParams.get("page"));
  const [flag, setFlag] = useState(false);
  // eslint-disable-next-line
  const [processQuery, setProcessQuery] = useState();
  // eslint-disable-next-line
  const [topicQuery, setTopicQuery] = useState();
  const [editUpdateDataPage, setEditUpdateDataPage] = useState();
  const [topicEditData, setEditTopicData] = useState();
  const [processEditData, setProcessEditData] = useState();
  // eslint-disable-next-line
  const [employeeData, setEmployeeData] = useState();
  const [trainingData, setTrainingData] = useState();
  const [communityData, setCommunityData] = useState();
  const [applicationData, setApplicationData] = useState();
  const [organizationData, setOrganisationData] = useState();
  const [processIncludedData, setProcessIncludedData] = useState();
  const [topicIncludedData, setTopicInculdedData] = useState();
  const [initialProcessData, setIniProcessData] = useState();
  const [iniTopicData, setIniTopicData] = useState();
  const [processViewData, setProcessViewData] = useState();
  const [viewTopicData, setViewTopicData] = useState();
  const [addProcessData, setAddProcessData] = useState();
  const [key, setKey] = useState("1");

  if (flag === true) {
    const param = `${getHostUrl()}topics?include=orgs,owners&use_pagination=false`;
    const param1 = `${getHostUrl()}process?include=owners,trainings,communities,apps,orgs&use_pagination=false`;
    (async () => {
      const res = await getData(param);
      const res1 = await getData(param1);

      await setTopicData(res.data.data);
      await setProcessData(res1.data.data);
      await setProcessIncludedData(res1.data.included);
      await setTopicInculdedData(res.data.included);
    })();

    setFlag(false);
  }

  useEffect(() => {
    const param = `${getHostUrl()}process?include=owners,trainings,communities,apps,orgs&use_pagination=false`;
    const param1 = `${getHostUrl()}topics?include=orgs,owners&use_pagination=false`;
    const community = `${getHostUrl()}community?use_pagination=false`;
    const training = `${getHostUrl()}training?use_pagination=false`;
    const application = `${getHostUrl()}application?use_pagination=false`;
    const orgData = `${getHostUrl()}organization/search?search=SE FIN GS&use_pagination=false`;

    async function getProcessResponse() {
      const adRes = await getData(
        `${getHostUrl()}user/check/${
          JSON.parse(sessionStorage.getItem("userData")).id
        }`
      );
      await setIsAdmin(adRes.data);
      if (adRes.data) {
        try {
          const res = await getData(param);
          const res1 = await getData(param1);
          const res3 = await getData(community);
          const res4 = await getData(training);
          const res5 = await getData(application);
          const res6 = await getData(orgData);
          if (res) {
            setRes(true);
            setProcessData(res.data.data);
            setProcessIncludedData(res.data.included);
            setTopicInculdedData(res1.data.included);
            setTopicData(res1.data.data);
            setApplicationData(res5.data);
            setCommunityData(res3.data);
            setTrainingData(res4.data);
            setOrganisationData(res6.data);
            setIniTopicData(res1.data.data);
            setIniProcessData(res.data.data);
          }
        } catch (err) {
          if (err) {
            setRes(false);
            if (err.response.status === 401) {
              setRes(true);
            }
          }
        }
      }
    }
    getProcessResponse();
    addQueryParam("page", pageLevel);
    // eslint-disable-next-line
  }, []);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  useEffect(() => {
    SearchProcess(initialProcessData);
    // eslint-disable-next-line
  }, [processQuery]);

  useEffect(() => {
    SearchTopic(iniTopicData);
    // eslint-disable-next-line
  }, [topicQuery]);

  const SearchTopic = (data) => {
    const newList = data?.filter((item) =>
      item.attributes["topics-name"]
        .toLowerCase()
        .includes(topicQuery?.toLowerCase())
    );
    setTopicData(newList);
  };

  const SearchProcess = (data) => {
    const newList = data?.filter((item) =>
      item.attributes["title"]
        .toLowerCase()
        .includes(processQuery?.toLowerCase())
    );
    setProcessData(newList);
  };

  return (
    <div className="main-page-margin">
      {isAdmin !== null ? (
        <>
          {isAdmin ? (
            <div className="main-manage">
              <>
                <div className="process-header">
                  <button
                    className="fw-bold border-0 bg-transparent"
                    onClick={() => {
                      if (pageLevel === "process") {
                        navigate("/manage_users");
                      } else if (pageLevel === "addTopic") {
                        setPageLevel("topic");
                      } else if (pageLevel === "editTopic") {
                        setPageLevel("topic");
                      } else if (pageLevel === `topic_id${viewTopicData?.id}`) {
                        setPageLevel("topic");
                      } else if (pageLevel === "editPreview") {
                        setPageLevel("editProcess");
                        setFlag(false);
                      } else if (pageLevel === "addPreview") {
                        setPageLevel("addProcess");
                        setFlag(false);
                      } else if (pageLevel === "addProcess") {
                        setPageLevel("process");
                      } else if (pageLevel === "topic") {
                        navigate("/manage_users");
                      } else {
                        setPageLevel("process");
                      }
                    }}
                  >
                    <ImageSvgs name="goBack" /> Back
                  </button>
                  {pageLevel === "topic" || pageLevel === "process" ? (
                    <div className=" d-flex justify-content-between ">
                      <div>
                        <ButtonGroup className="me-2 process-btn">
                          <Button
                            className={`main-title-option ${
                              key === "1" && pageLevel === "process"
                                ? "active-option"
                                : "not-active main-title-option "
                            } `}
                            onClick={() => {
                              setKey("1");
                              setPageLevel("process");
                              addQueryParam("page", "process");
                            }}
                          >
                            Processes ({processData && processData?.length})
                          </Button>
                          <Button
                            className={`main-title-option ${
                              key === "2"
                                ? "active-option"
                                : "main-title-option"
                            } `}
                            onClick={() => {
                              setKey("2");
                              setPageLevel("topic");
                              addQueryParam("page", "topic");
                            }}
                          >
                            Topics ({topicData && topicData.length})
                          </Button>
                        </ButtonGroup>
                      </div>
                      <div className="sub-bar">
                        <div className="search-div">
                          {pageLevel === "process" ? (
                            <Button
                              className="app-secondary-btn"
                              onClick={() => {
                                setPageLevel("addProcess");
                                addQueryParam("page", "addProcess");
                                sessionStorage.removeItem("formData");
                              }}
                            >
                              Add New Process
                            </Button>
                          ) : (
                            <Button
                              className="app-secondary-btn"
                              onClick={() => {
                                setPageLevel("addTopic");
                                addQueryParam("page", "addTopic");
                              }}
                            >
                              Add New Topic
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {pageLevel === "process" ? (
                  <div>
                    {res ? (
                      <div className="process-table">
                        <div className="user-table table table-responsive">
                          <ProcessTable
                            setPageLevel={setPageLevel}
                            addQueryParam={addQueryParam}
                            processData={processData && processData}
                            setFlag={setFlag}
                            setProcessEditData={setProcessEditData}
                            processIncludedData={processIncludedData}
                            setProcessViewData={setProcessViewData}
                            organizationData={organizationData}
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{ maxWidth: "100%", height: "35rem" }}>
                        <Loader />
                      </div>
                    )}
                  </div>
                ) : pageLevel === "topic" ? (
                  <div className="topic-table">
                    <div className="user-table table table-responsive">
                      <TopicTable
                        setPageLevel={setPageLevel}
                        addQueryParam={addQueryParam}
                        topicData={topicData && topicData}
                        setFlag={setFlag}
                        setEditTopicData={setEditTopicData}
                        topicIncludedData={topicIncludedData}
                        organizationData={organizationData && organizationData}
                        employeeData={employeeData && employeeData}
                        setViewTopicData={setViewTopicData}
                        editUpdateDataPage={
                          editUpdateDataPage && editUpdateDataPage
                        }
                      />
                    </div>
                  </div>
                ) : pageLevel === "addProcess" ? (
                  <AddAndEditProcess
                    title={"Add New Process"}
                    setPageLevel={setPageLevel}
                    addQueryParam={addQueryParam}
                    setFlag={setFlag}
                    processData={processData}
                    pageLevel={pageLevel}
                    organizationData={organizationData && organizationData}
                    processIncludedData={processIncludedData}
                    trainingData={trainingData}
                    applicationData={applicationData}
                    communityData={communityData}
                    initialProcessData={
                      initialProcessData && initialProcessData
                    }
                    setProcessViewData={setProcessViewData}
                    setEditUpdateDataPage={setEditUpdateDataPage}
                    setAddProcessData={setAddProcessData}
                    addProcessData={addProcessData && addProcessData}
                  />
                ) : pageLevel === "editProcess" ? (
                  <AddAndEditProcess
                    title={"Edit Process"}
                    setPageLevel={setPageLevel}
                    addQueryParam={addQueryParam}
                    setFlag={setFlag}
                    processData={processData}
                    processEditData={processEditData && processEditData}
                    pageLevel={pageLevel}
                    organizationData={organizationData}
                    processIncludedData={processIncludedData}
                    trainingData={trainingData}
                    applicationData={applicationData}
                    communityData={communityData}
                    initialProcessData={
                      initialProcessData && initialProcessData
                    }
                    setProcessViewData={setProcessViewData}
                  />
                ) : pageLevel === "addTopic" ? (
                  <AddTopic
                    title={"Add New Topic"}
                    setPageLevel={setPageLevel}
                    addQueryParam={addQueryParam}
                    setFlag={setFlag}
                    topicIncludedData={topicIncludedData}
                    organizationData={organizationData && organizationData}
                    setEditTopicData={setEditTopicData}
                    iniTopicData={iniTopicData && iniTopicData}
                  />
                ) : pageLevel === `topic_id${viewTopicData?.id}` ? (
                  <ViewTopic
                    title={"View Topic"}
                    viewTopicData={viewTopicData && viewTopicData}
                    topicIncludedData={topicIncludedData && topicIncludedData}
                  />
                ) : pageLevel === "editTopic" ? (
                  <AddTopic
                    title={"Edit Topic"}
                    addQueryParam={addQueryParam}
                    setPageLevel={setPageLevel}
                    setFlag={setFlag}
                    topicEditData={topicEditData}
                    pageLevel={pageLevel}
                    topicIncludedData={topicIncludedData}
                    organizationData={organizationData && organizationData}
                    iniTopicData={iniTopicData && iniTopicData}
                  />
                ) : pageLevel === "editPreview" ? (
                  <PreviewProcess
                    title={"Preview"}
                    processViewData={processViewData}
                    processEditData={processEditData}
                    processIncludedData={processIncludedData}
                    applicationData={applicationData && applicationData}
                    communityData={communityData && communityData}
                    organizationData={organizationData && organizationData}
                    trainingData={trainingData && trainingData}
                    processData={processData && processData}
                    employeeData={employeeData}
                  />
                ) : pageLevel === "addPreview" ? (
                  <PreviewProcess
                    title={"Preview"}
                    processViewData={processViewData}
                    processEditData={processEditData}
                    processIncludedData={processIncludedData}
                    applicationData={applicationData && applicationData}
                    communityData={communityData && communityData}
                    organizationData={organizationData && organizationData}
                    trainingData={trainingData && trainingData}
                    processData={processData && processData}
                    employeeData={employeeData}
                  />
                ) : pageLevel === "viewProcessDetails" ? (
                  <PreviewProcess
                    title={"View Details"}
                    processViewData={processViewData && processViewData}
                    processIncludedData={processIncludedData}
                  />
                ) : (
                  <div className="non-admin">
                    <ImageSvgs name="non_admin" />
                    <h1 className="text-danger">Unauthorized user!</h1>
                    <h4>You do not have admin access rights.</h4>
                    <button
                      id="common-button"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Go to Home
                    </button>
                  </div>
                )}
              </>
            </div>
          ) : (
            <div
              className="non-admin d-flex justify-content-center"
              style={{ height: "100vh" }}
            >
              <div className="my-auto">
                <span className="mx-auto d-flex justify-content-center">
                  <ImageSvgs name="non_admin" />
                </span>
                <h1 className="text-danger d-flex justify-content-center">
                  Unauthorized user!
                </h1>
                <h4 className="mx-auto d-flex justify-content-center">
                  You do not have admin access rights.
                </h4>
                <button
                  className="d-flex mx-auto"
                  id="common-button"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Home
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}
