import React from "react";
import { useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { deleteData } from "../../services/apiService";
import { UserModal } from "../../utils/toolsUtils";
import { getHostUrl, getBaseUrl } from "../../config/environment";

const TrainingsTable = ({ data, included, setLevel, setIsDeleted }) => {
  const navigate = useNavigate();
  const initialData = data;
  const [tableData, setTableData] = useState(data);
  const [modalShow, setModalShow] = useState(false);
  const [trainingData, setTrainingData] = useState();
  const [userData, setUserData] = useState();
  const [showUserModal, setShowUserModal] = useState(false);
  const [nameQuery, setNameQuery] = useState("");
  const [descQuery, setDescQuery] = useState("");

  const nameSearch = (query) => {
    if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0"
    ) {
      setTableData(initialData);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["training-name"].toLowerCase().includes(query)
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["training-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() > "0" &&
      descQuery?.length.toString() > "0"
    ) {
      setTableData(
        initialData?.filter(
          (item) =>
            item.attributes["training-name"].toLowerCase().includes(query) &&
            item.attributes["training-description"]
              .toLowerCase()
              .includes(descQuery.toLowerCase())
        )
      );
    }
  };

  const descSearch = (query) => {
    if (
      nameQuery?.length.toString() === "0" &&
      query.length.toString() === "0"
    ) {
      setTableData(initialData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query.length.toString() === "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["training-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query.length.toString() !== "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["training-description"].toLowerCase().includes(query)
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      query.length.toString() > "0"
    ) {
      setTableData(
        initialData?.filter(
          (item) =>
            item.attributes["training-name"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["training-description"]
              .toLowerCase()
              .includes(query)
        )
      );
    }
  };

  const DeleteTrainingModal = (props) => {
    const [loading, setLoading] = useState(false);

    const deleteTraining = async (id) => {
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      };
      try {
        setLoading(true);
        // eslint-disable-next-line
        const res = await deleteData(`${getHostUrl()}training/${id}`, headers);
        setLoading(false);
        setIsDeleted(true);
        props.onHide();
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Training
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Name: {props.trainingData.attributes["training-name"]}</h5>
          <p>
            Are you sure you want to delete this training? <br />
            All the connections of this training within FIN GS would be lost.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="common-cancel-btn"
            // id="common-favbutton"
            onClick={props.onHide}
          >
            Cancel
          </Button>
          <Button
            id="common-favbutton"
            onClick={() => {
              deleteTraining(props.trainingData.id);
            }}
          >
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const setUsers = (list) => {
    let userList = [];
    // eslint-disable-next-line
    list.map((e) => {
      let newElem = included?.filter(
        (item) =>
          item.type === "trainers" && item.id.toString() === e.id.toString()
      )[0];
      userList.push(newElem);
    });
    return userList;
  };

  return (
    <>
      <Table className="main-table" striped>
        <thead className="user-table-head">
          <tr className="border-bottom">
            <th>Training Name</th>
            <th>Training Description</th>
            <th>Trainer</th>
            <th className="text-center">Actions</th>
          </tr>
          <tr style={{ height: "3.5rem" }}>
            <th>
              <div className="search-div mb-2">
                <div className="app-primary-input-field">
                  <input
                    className="search-bar me-3 w-100"
                    style={{
                      height: "32px !important",
                      width: "95% !important",
                    }}
                    type="search"
                    placeholder=" Search by name..."
                    value={nameQuery}
                    onChange={(e) => {
                      setNameQuery(e.target.value);
                      nameSearch(e.target.value.toLowerCase());
                    }}
                  />
                </div>
              </div>
            </th>
            <th>
              <div className="search-div mb-2">
                <div className="app-primary-input-field">
                  <input
                    className="me-3 w-100"
                    type="search"
                    placeholder=" Search by description..."
                    value={descQuery}
                    onChange={(e) => {
                      setDescQuery(e.target.value);
                      descSearch(e.target.value.toLowerCase());
                    }}
                  />
                </div>
              </div>
            </th>
            <th className="text-start">
              <div className="app-primary-input-field mb-2">
                <input
                  disabled
                  className="me-3 w-100"
                  type="search"
                  placeholder="Disabled"
                />
              </div>
            </th>
            <th>
              <div className="app-primary-input-field mb-2">
                <input
                  disabled
                  className="me-3 w-100"
                  type="search"
                  placeholder="Disabled"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0 ? (
            <>
              {tableData
                ?.sort((a, b) =>
                  a.attributes["training-name"].localeCompare(
                    b.attributes["training-name"]
                  )
                )
                .map((item) => (
                  <tr key={item.id} className="table-striped-bg">
                    <td>
                      <span
                        className="me-2 app-table-name"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Visit Training"
                      >
                        <Link
                          className="app-table-name"
                          style={{ textDecoration: "none" }}
                          to={`/landscape/trainings?id=${item.id}`}
                          state={{ prevPath: window.location.href }}
                        >
                          {item.attributes["training-name"]}
                        </Link>
                      </span>
                    </td>
                    <td className="d-flex my-auto">
                      <span className="text-overflow">
                        {item.attributes["training-description"]}
                      </span>
                    </td>

                    <td style={{ textAlign: "left" }}>
                      <span className="ms-3">
                        {item?.relationships?.trainers?.data.length > 0 ? (
                          <>
                            {item?.relationships?.trainers?.data
                              .slice(0, 3)
                              .map((elem, index) => {
                                let owner = included?.filter(
                                  (item) =>
                                    item.type === "trainers" &&
                                    item.id.toString() === elem.id.toString()
                                )[0];

                                return (
                                  <span
                                    className="me-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate(
                                        `/employee_relation?id=${owner?.attributes["employee-id"]}`
                                      )
                                    }
                                  >
                                    {owner?.attributes?.employee?.data
                                      ?.attributes["profile-picture"] ? (
                                      <img
                                        className="my-auto"
                                        src={`${getBaseUrl()}${
                                          owner?.attributes?.employee?.data
                                            ?.attributes["profile-picture"]
                                        }`}
                                        style={{
                                          height: "2rem",
                                          width: "2rem",
                                          border: "1.5px solid black",
                                          borderRadius: "7rem",
                                          objectFit: "contain",
                                        }}
                                        alt="User Img"
                                      />
                                    ) : (
                                      <Avatar
                                        name={
                                          owner?.attributes?.employee?.data
                                            ?.attributes["preferred-name"]
                                        }
                                        size="32"
                                        round={true}
                                      />
                                    )}
                                  </span>
                                );
                              })}
                            {item?.relationships?.trainers?.data.length > 3 ? (
                              <>
                                <button
                                  className="more-user-btn"
                                  onClick={async () => {
                                    let userList = setUsers(
                                      item?.relationships?.trainers?.data
                                    );
                                    setUserData(userList);
                                    setShowUserModal(true);
                                  }}
                                >
                                  +{" "}
                                  {item?.relationships?.trainers?.data.length -
                                    3}{" "}
                                  more
                                </button>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <b>-</b>
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="d-flex mt-2 justify-content-center">
                        <span
                          className="me-3 d-flex"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit Training"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            sessionStorage.setItem(
                              "trainingData",
                              JSON.stringify(item)
                            );
                            let flag = item.attributes["training-link"].link;
                            setLevel(typeof flag);
                          }}
                        >
                          <ImageSvgs name="edit" />
                        </span>

                        <span
                          className="me-2 d-flex"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Training"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalShow(true);
                            setTrainingData(item);
                          }}
                        >
                          <ImageSvgs name="delete" />
                        </span>
                      </span>
                    </td>
                  </tr>
                ))}
            </>
          ) : (
            <tr className="w-100">
              <td
                className="w-100 mt-2"
                style={{
                  textAlign: "center",
                  backgroundColor: "#e9ecef ",
                }}
              >
                <p className="fw-bold mb-0">No matching records found.</p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {modalShow ? (
        <DeleteTrainingModal
          trainingData={trainingData}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      ) : null}
      {showUserModal === true ? (
        <UserModal
          title={"Trainers:-"}
          listData={userData}
          show={showUserModal}
          onHide={() => setShowUserModal(false)}
        />
      ) : null}
    </>
  );
};

export default TrainingsTable;
