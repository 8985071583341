import "../../../assets/scss/common.scss";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { chunk } from "lodash";
import { Button } from "react-bootstrap";
import { getBaseUrl } from "../../../config/environment";
import ImageSvgs from "../../ImageSvgs";
import MyVerticallyCenteredModal from "../../CommonComponents/ReadMore";
import CarouselExpertComponent from "../../CommonComponents/CarouselExpertComponent";
import CarouselConnections from "../../CommonComponents/CarouselConnections";

export default function ProcessUserPage(props) {
  const processData = props?.data.data;
  const includedData = props?.data.included;
  const [listData, setListData] = useState(processData);
  const [activeProcess, setActiveProcess] = useState();
  const [owner, setOwner] = useState();
  const [processConnections, setProcessConnections] = useState();
  const [btnCheck, setBtnCheck] = useState(false);
  const [copy, setCopy] = useState(false);
  const [copy2, setCopy2] = useState(false);
  const [copyPageUrl, setCopyPageUrl] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const widthRef = useRef(null);
  const pageUrl = window.location.href;

  const handleProcessSearch = (event) => {
    const newList = processData?.filter((value) =>
      value.attributes.title.toLowerCase().includes(event.toLowerCase())
    );
    setListData(newList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      let id = url.searchParams.get("process_id");

      if (id === "0") {
        setProcess(processData[0].id);
      } else {
        setProcess(id);
      }
    })();

    // eslint-disable-next-line
  }, [activeProcess]);

  const setProcess = async (data) => {
    let processVal = processData?.filter(
      (item) => item.id.toString() === data?.toString()
    )[0];

    setActiveProcess(processVal);

    addQueryParam("process_id", processVal && processVal.id);

    if (processVal?.attributes.desc?.length > 300) {
      setIsOverflowing(true);
    }

    let ownerData =
      processVal?.relationships.owners.data.length > 0
        ? processVal?.relationships.owners.data.map((item) => {
            return includedData?.filter(
              // eslint-disable-next-line
              (owners) =>
                owners.type === "owners" &&
                owners.id.toString() === item.id.toString()
            )[0].attributes.employee.data;
          })
        : [];

    let tempOwnerData = chunk(
      ownerData
        .sort((a, b) =>
          a.attributes["preferred-name"].localeCompare(
            b.attributes["preferred-name"]
          )
        )
        .sort(
          (a, b) =>
            Number(
              includedData?.filter(
                // eslint-disable-next-line
                (owners) =>
                  owners.type === "owners" &&
                  owners.attributes["employee-id"].toString() ===
                    b.id.toString()
              )[0].attributes["is-owners"]
            ) -
            Number(
              includedData?.filter(
                // eslint-disable-next-line
                (owners) =>
                  owners.type === "owners" &&
                  owners.attributes["employee-id"].toString() ===
                    a.id.toString()
              )[0].attributes["is-owners"]
            )
        ),
      4
    );

    let appData =
      processVal?.relationships.apps.data.length > 0
        ? processVal?.relationships.apps.data.map((item) => {
            let apps = includedData?.filter(
              (app) =>
                app.type === "application" &&
                app.id.toString() === item.id.toString()
            )[0];
            return apps.attributes["apps-process"].data;
          })
        : [];

    let comData =
      processVal?.relationships.communities.data.length > 0
        ? processVal?.relationships.communities.data.map((item) => {
            let community = includedData?.filter(
              (com) =>
                com.type === "community" &&
                com.id.toString() === item.id.toString()
            )[0];
            return community.attributes["process-communities"].data;
          })
        : [];

    let trainingData =
      processVal?.relationships.trainings.data.length > 0
        ? processVal?.relationships.trainings.data.map((item) => {
            let trainings = includedData?.filter(
              (value) =>
                value.type === "trainings" &&
                value.id.toString() === item.id.toString()
            )[0];
            return trainings.attributes["training-master"].data;
          })
        : [];

    let orgData =
      processVal?.relationships.orgs.data.length > 0
        ? processVal?.relationships.orgs.data.map((item) => {
            let orgs = includedData?.filter(
              (org) =>
                org.type === "orgs" && org.id.toString() === item.id.toString()
            )[0];
            return orgs.attributes["org-master"].data;
          })
        : [];

    setProcessConnections({
      appData:
        appData.length > 0
          ? chunk(
              appData.sort((a, b) =>
                a.attributes["app-title"].localeCompare(
                  b.attributes["app-title"]
                )
              ),
              5
            )
          : [],
      comData:
        comData.length > 0
          ? chunk(
              comData.sort((a, b) =>
                a.attributes["community-name"].localeCompare(
                  b.attributes["community-name"]
                )
              ),
              5
            )
          : [],
      orgData:
        orgData.length > 0
          ? chunk(
              orgData.sort((a, b) =>
                a.attributes["org-title"].localeCompare(
                  b.attributes["org-title"]
                )
              ),
              5
            )
          : [],
      trainingData:
        trainingData.length > 0
          ? chunk(
              trainingData.sort((a, b) =>
                a.attributes["training-name"].localeCompare(
                  b.attributes["training-name"]
                )
              ),
              5
            )
          : [],
    });

    setOwner(tempOwnerData);
  };

  const processId = activeProcess?.attributes?.["parent-process-id"];

  const processName = processData?.filter(
    (data) =>
      data.type === "process" && data?.id?.toString() === processId?.toString()
  );

  return (
    <>
      <div className="d-flex flex-row">
        <div className="leftSide">
          <h5 className="appHead">Process List:-</h5>
          <form className="search-app" onSubmit={(e) => handleSubmit(e)}>
            <div className="app-primary-input-field" style={{ width: "16rem" }}>
              <input
                type="search"
                placeholder="Search Process..."
                onChange={(e) => handleProcessSearch(e.target.value)}
              />
            </div>
          </form>
          <div className="appList" style={{ marginTop: "10px" }}>
            {listData
              ?.sort((a, b) =>
                a.attributes["title"].localeCompare(b.attributes["title"])
              )
              ?.map((process, key) => {
                return (
                  <>
                    <div
                      className={`${
                        activeProcess?.id?.toString() === process.id?.toString()
                          ? "border-bottom appTag act"
                          : "border-bottom appTag"
                      }`}
                      key={process.id}
                      onClick={async () => {
                        setProcess(process?.id);
                        // eslint-disable-next-line
                        let data = await setActiveProcess(process);

                        const e = widthRef.current;
                        if (e?.offsetHeight < e?.scrollHeight) {
                          setIsOverflowing(true);
                        } else {
                          setIsOverflowing(false);
                        }

                        setActiveProcess(process);
                      }}
                    >
                      <span className="capitalize" style={{ fontSize: "18px" }}>
                        {process.attributes.title}
                      </span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>

        <div className="rightSide d-flex flex-column">
          <div className="titDiv p-3">
            <div className="innerDiv border-bottom pb-2">
              <div className="details d-flex flex-column ">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row pb-2 justify-content-between">
                    <span>
                      <span style={{ fontSize: "18px" }}>
                        <strong>{activeProcess?.attributes?.title}</strong>
                      </span>

                      <span className="px-1">
                        <button
                          className=" bg-transparent border-0"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={!copy ? "Copy Page Url" : "Copied"}
                          disabled={copy}
                          onClick={() => {
                            navigator.clipboard.writeText(pageUrl);
                            setCopyPageUrl(true);
                            setTimeout(() => {
                              setCopyPageUrl(false);
                            }, 300);
                          }}
                        >
                          {copyPageUrl === false ? (
                            <>
                              <ImageSvgs name="expertCopy" />
                            </>
                          ) : (
                            <>
                              <ImageSvgs name="tickMark" />
                            </>
                          )}
                        </button>
                      </span>
                    </span>

                    {activeProcess?.attributes?.["template-url"] ? (
                      <Button
                        // disabled={btnCheck}
                        className="common-cancel-btn"
                        onClick={async () => {
                          setBtnCheck(true);
                          const url = `${getBaseUrl()}${
                            activeProcess?.attributes?.["template-url"]
                          }`;

                          const extension = activeProcess.attributes[
                            "template-url"
                          ].substring(
                            activeProcess.attributes[
                              "template-url"
                            ].lastIndexOf(".")
                          );
                          const fileName = `${activeProcess?.attributes.title}${extension}`;

                          await axios({
                            url,
                            method: "GET",
                            responseType: "blob",
                          })
                            .then((response) => {
                              const href = window.URL.createObjectURL(
                                response.data
                              );

                              const anchorElement = document.createElement("a");
                              anchorElement.href = href;
                              anchorElement.download = fileName;

                              document.body.appendChild(anchorElement);
                              anchorElement.click();

                              document.body.removeChild(anchorElement);
                              window.URL.revokeObjectURL(href);
                              setBtnCheck(false);
                            })
                            .catch((error) => {
                              setBtnCheck(false);
                              return error;
                            });
                        }}
                      >
                        {btnCheck ? "Downloading..." : "Download Template"}
                      </Button>
                    ) : null}
                  </div>

                  <div className="pb-2 d-flex flex-row">
                    <div className="d-flex flex-row">
                      <div className="min-width ">
                        <strong>Description</strong>
                      </div>
                      <span>
                        <strong>:</strong>
                      </span>
                    </div>

                    <div className="px-1">
                      <div>
                        <span
                          className={
                            isOverflowing &&
                            activeProcess?.attributes?.desc?.length > 300
                              ? "limited-space mx-0 d-flex flex-column"
                              : "not-read-more mx-0 d-flex flex-column"
                          }
                          ref={widthRef}
                        >
                          <div style={{ whiteSpace: "pre-line" }}>
                            {activeProcess?.attributes?.desc?.replace(
                              /\\n/g,
                              "\n"
                            )}
                          </div>
                        </span>
                      </div>
                      <p
                        className=" mx-0 mb-0 read-more-btn"
                        style={{ cursor: "pointer", color: "#8a00e5" }}
                        onClick={() => {
                          setShowInfoModal(true);
                        }}
                      >
                        {isOverflowing &&
                        activeProcess?.attributes?.desc?.length > 300
                          ? "Read more"
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className=" d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Periodicity</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    <span className="px-1">
                      <span>
                        {activeProcess?.attributes?.["periodicity-period"] === 1
                          ? "Monthly Based Process"
                          : activeProcess?.attributes?.[
                              "periodicity-period"
                            ] === 2
                          ? "Quartely Based Process"
                          : activeProcess?.attributes?.[
                              "periodicity-period"
                            ] === 3
                          ? "Annually Based Process"
                          : "N/A"}
                      </span>
                    </span>
                  </div>

                  <div className=" d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Process Date & Time</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    {activeProcess?.attributes?.["process-day"] ||
                    activeProcess?.attributes?.["process-time"] ? (
                      <div
                        className="d-flex flex-row 
                    "
                      >
                        <span className="px-1 ">
                          {" "}
                          {activeProcess?.attributes?.["process-day"]}
                        </span>
                        <span>
                          {activeProcess?.attributes?.["process-day"] &&
                          activeProcess?.attributes?.["process-time"]
                            ? ","
                            : " "}
                        </span>{" "}
                        <span className="px-2">
                          {activeProcess?.attributes?.["process-time"]}
                        </span>
                      </div>
                    ) : (
                      <span className="px-1"> N/A</span>
                    )}
                  </div>
                  <div className="d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Documentation </strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    {activeProcess?.attributes?.["handbook-link-url"] ||
                    activeProcess?.attributes["wiki-link-url"] ? (
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row">
                          <div className=" preview-link px-1 mb-0">
                            <a
                              href={
                                activeProcess?.attributes?.["handbook-link-url"]
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {activeProcess?.attributes[
                                "handbook-link-url"
                              ] !== undefined ? (
                                <div className="overflow-ellipses">
                                  {
                                    activeProcess?.attributes[
                                      "handbook-link-url"
                                    ]
                                  }
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </a>
                          </div>
                          {activeProcess?.attributes["handbook-link-url"]
                            ?.length > 0 ? (
                            <span>
                              <button
                                className=" bg-transparent border-0"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={!copy ? "Copy Handbook Link" : "Copied"}
                                disabled={copy}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    activeProcess?.attributes[
                                      "handbook-link-url"
                                    ]
                                  );
                                  setCopy(true);
                                  setTimeout(() => {
                                    setCopy(false);
                                  }, 300);
                                }}
                              >
                                {copy === false ? (
                                  <>
                                    <ImageSvgs name="expertCopy" />
                                  </>
                                ) : (
                                  <>
                                    <ImageSvgs name="tickMark" />
                                  </>
                                )}
                              </button>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-flex flex-row">
                          {activeProcess?.attributes["wiki-link-url"] !==
                            undefined && (
                            <>
                              <div className="preview-link px-1 mb-0">
                                <a
                                  href={
                                    activeProcess?.attributes?.["wiki-link-url"]
                                  }
                                  // className="preview-link"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {activeProcess?.attributes[
                                    "wiki-link-url"
                                  ] !== undefined ? (
                                    <div className="overflow-ellipses">
                                      {
                                        activeProcess?.attributes[
                                          "wiki-link-url"
                                        ]
                                      }
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </div>
                              <span>
                                {activeProcess?.attributes["wiki-link-url"]
                                  ?.length > 0 ? (
                                  <span>
                                    <button
                                      className=" bg-transparent border-0"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={
                                        !copy
                                          ? "Copy Wikisphere link"
                                          : "Copied"
                                      }
                                      disabled={copy}
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          activeProcess?.attributes[
                                            "wiki-link-url"
                                          ]
                                        );
                                        setCopy2(true);
                                        setTimeout(() => {
                                          setCopy2(false);
                                        }, 300);
                                      }}
                                    >
                                      {copy2 === false ? (
                                        <>
                                          <ImageSvgs name="expertCopy" />
                                        </>
                                      ) : (
                                        <>
                                          <ImageSvgs name="tickMark" />
                                        </>
                                      )}
                                    </button>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <span className="px-1"> N/A</span>
                    )}
                  </div>
                  <span className="d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Parent Process</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    {processName?.length > 0 ? (
                      <span
                        className={
                          processName?.length > 0
                            ? "px-1 app-table-name"
                            : "px-1"
                        }
                        onClick={() => {
                          addQueryParam("process_id", processId);
                          setProcess(processName?.[0]?.id);
                        }}
                      >
                        {processName?.length > 0 &&
                          processName[0]?.attributes.title}
                      </span>
                    ) : (
                      <span className="px-1"> N/A</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="d-flex  flex-column experts-comp border-bottom pb-3 p-3">
              <h6 className="fw-bold me-3 my-auto">
                Owners & Deputies of {activeProcess?.attributes?.title} are :-{" "}
              </h6>
              <div className="experts-section mt-3">
                <div className="d-flex flex-row justify-content-between">
                  {owner?.length > 0 ? (
                    <CarouselExpertComponent
                      data={owner}
                      includedData={includedData}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="section">
              <div className="innerSec">
                <div className="d-flex flex-column mt-3">
                  <h6>
                    <b>{activeProcess?.attributes.title} is connected to :- </b>
                  </h6>
                  <div className="p-3 page-connections-section">
                    <div className="mt-4 d-flex flex-column w-100">
                      {processConnections?.comData.length > 0 ? (
                        <h6 className="fw-bold ms-4 d-flex">Communities</h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Communities:
                          <p className="mb-0 ms-2 text-muted">N/A</p>
                        </h6>
                      )}
                      <div className="d-flex flex-row w-100">
                        {processConnections?.comData.length > 0 && (
                          <CarouselConnections
                            data={processConnections?.comData}
                          />
                        )}
                      </div>
                    </div>

                    <div className="mt-4 d-flex flex-column w-100">
                      {processConnections?.trainingData.length > 0 ? (
                        <h6 className="fw-bold ms-4">Trainings</h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Trainings:
                          <p className="mb-0 ms-2 text-muted">N/A</p>
                        </h6>
                      )}
                      <div className="d-flex flex-row w-100">
                        {processConnections?.trainingData.length > 0 && (
                          <CarouselConnections
                            data={processConnections?.trainingData}
                          />
                        )}
                      </div>
                    </div>

                    <div className="mt-4 d-flex flex-column w-100">
                      {processConnections?.appData.length > 0 ? (
                        <h6 className="fw-bold ms-4">Applications</h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Applications:
                          <p className="mb-0 ms-2 text-muted">N/A</p>
                        </h6>
                      )}
                      <div className="d-flex flex-row w-100">
                        {processConnections?.appData.length > 0 && (
                          <CarouselConnections
                            data={processConnections?.appData}
                          />
                        )}
                      </div>
                    </div>

                    <div className="mt-4 d-flex flex-column w-100">
                      {processConnections?.orgData.length > 0 > 0 ? (
                        <h6 className="fw-bold ms-4">Organization</h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Organization:
                          <p className="mb-0 ms-2 text-muted">N/A</p>
                        </h6>
                      )}
                      <div className="d-flex flex-row">
                        {processConnections?.orgData.length > 0 && (
                          <div className="w-100">
                            <CarouselConnections
                              data={processConnections?.orgData}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showInfoModal ? (
          <MyVerticallyCenteredModal
            show={showInfoModal}
            onHide={() => setShowInfoModal(false)}
            data={activeProcess && activeProcess}
          />
        ) : null}
      </div>
    </>
  );
}
