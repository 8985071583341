import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { getData } from "../services/apiService";
import { getHostUrl, getBaseUrl } from "../config/environment";
import { useNavigate, useLocation } from "react-router-dom";
import CommunitiesUpdatedUserPage from "../components/CommunityUserPages/CommunitiesUpdatedUserPage";

const CommunityPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const url = new URL(window.location.href);
  // eslint-disable-next-line
  const [data, setData] = useState();
  // eslint-disable-next-line
  const [colorData, setColorData] = useState();
  // eslint-disable-next-line
  const [category, setCategory] = useState();
  // eslint-disable-next-line
  const [loaded, setLoaded] = useState(false);
  // eslint-disable-next-line
  const [orgList, setOrgList] = useState();
  // eslint-disable-next-line
  const [level, setLevel] = useState(url.searchParams.get("page"));
  // eslint-disable-next-line
  const [comId, setComId] = useState(null);
  // eslint-disable-next-line
  const [myComs, setMyComs] = useState();
  const [communityTags, setCommunityTags] = useState([]);

  useEffect(() => {
    (async () => {
      setLoaded(false);
      const res = await getData(
        `${getHostUrl()}community?use_pagination=false`
      );
      const commnityTagRes = await getData(
        `${getBaseUrl()}/configurations/communityTags.json`
      );
      await setData(res.data.data);
      await setCommunityTags(commnityTagRes?.data?.communityTag);

      try {
        const myData = await getData(`${getHostUrl()}community/mycommunity`);
        await setMyComs(myData.data.data);
      } catch (err) {
        if (err) {
          if (err.response.status === 404) {
            await setMyComs([]);
          }
        }
      }

      const colors = await getData(
        `${getBaseUrl()}/configurations/community/bubbleChartColors.json`
      );
      await setColorData(colors.data.colorList);

      const catData = await getData(
        `${getBaseUrl()}/configurations/community/community-type.json`
      );
      await setCategory(catData.data.community_type);

      const ans = await getData(
        `${getHostUrl()}organization/search?search=SE FIN GS&use_pagination=false`
      );
      await setOrgList(ans.data.data);

      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get("page").includes("communities")) {
      setComId(
        url.searchParams
          .get("page")
          .slice(url.searchParams.get("page").lastIndexOf("s") + 1)
      );
    }
    addQueryParam("page", level);
  }, [level]);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  return (
    <div>
      <div className=" mx-auto mt-4 mb-5" style={{ width: "97%" }}>
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <div>
            <h2 className="fw-bold">Communities</h2>
          </div>
          <div>
            <Button
              className="app-tertiary-btn ms-3 mt-1"
              onClick={() => {
                if (state?.prevPath) {
                  window.location.assign(state?.prevPath);
                } else {
                  navigate("/");
                }
              }}
            >
              Close page X
            </Button>
          </div>
        </div>
        <div style={{ backgroundColor: "#f6f4ff" }}>
          <CommunitiesUpdatedUserPage
            orgList={orgList}
            data={data}
            communityTags={communityTags}
          />
        </div>
      </div>
    </div>
  );
};

export default CommunityPage;
