import { useEffect, useState } from "react";
import { getHostUrl } from "../config/environment";
import { getData } from "../services/apiService";
import { Button } from "react-bootstrap";
import ProcessUserPage from "../components/ProcessAdminComponent/ProcessUserPage/ProcessUserPage";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader/Loader";

export default function ProcessPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [processData, setProcessData] = useState();

  useEffect(() => {
    (async () => {
      const res = await getData(
        `${getHostUrl()}process?&include=owners,trainings,communities,apps,orgs&use_pagination=false`
      );
      await setProcessData(res.data);
    })();
  }, []);

  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <div>
            <h2 className="fw-bold">Process </h2>
          </div>
          <div>
            <Button
              className="app-tertiary-btn ms-3 mt-1"
              onClick={() => {
                if (state?.prevPath) {
                  window.location.assign(state?.prevPath);
                } else {
                  navigate("/");
                }
              }}
            >
              Close page X
            </Button>
          </div>
        </div>
        {processData ? (
          <div className="mt-3">
            <ProcessUserPage data={processData} />
          </div>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}
