import { useState, React, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import DashboardEditModal from "./DashboardEditModal";
import DashboardExpandModal from "./DashboardExpandModal";
import { getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { MultipleSelectDropdown } from "../CommonComponents/MultipleSelect";
import { Link } from "react-router-dom";
import "./SacDashboard.scss";

export default function SacDashboard(fullSize) {
  const param = `${getHostUrl()}user/config`;
  const [links, setLinks] = useState([]);
  const [iframeSrc, setIframeSrc] = useState([]);
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [dashboardView, setDashboardView] = useState(false);
  const [activeLink, setActiveLink] = useState();

  useEffect(() => {
    sacLinks();
    // eslint-disable-next-line
  }, []);

  const sacLinks = async () => {
    try {
      const links = await getData(param);

      if (
        links.data.data.attributes.configuration.sacLinks?.length > 0 &&
        links?.status === 200
      ) {
        setActiveLink(links.data.data.attributes.configuration.sacLinks[0]?.id);
        setLinks(links.data.data.attributes.configuration.sacLinks);
        setIframeSrc([links.data.data.attributes.configuration.sacLinks[0]]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onHide = () => {
    setShowDashboardModal(false);
  };

  const handleOnChange = (value) => {
    setIframeSrc(value);
  };

  return (
    <div className={fullSize ? "w-100" : "w-50"}>
      <div className="main-sac-div">
        <div clssName="grid-container w-100 mx-0 mr-0">
          <div className="d-flex">
            <Col xs={6}>
              <div>
                <h4 class="sec-title fw-bold mt-2">SAC Dashboards</h4>
              </div>
            </Col>

            <Col xs={6} className="d-flex justify-content-end">
              <div>
                <Button
                  className="app-primary-btn"
                  onClick={() => setShowDashboardModal(true)}
                >
                  Manage Dashboards
                </Button>
              </div>
            </Col>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between mt-1 pt-2">
          <div className="d-flex links-container">
            <div className="app-primary-dropdown" style={{ width: "16.5rem" }}>
              <MultipleSelectDropdown
                multi={false}
                color="#8A00E5"
                placeholder="Select Link..."
                className="dropdown-select mb-2"
                keepSelectedInList={true}
                options={
                  links?.length > 0
                    ? links?.map((elem) => ({
                        id: elem.id,
                        name: elem.name,
                        link: elem.link,
                      }))
                    : []
                }
                onChange={handleOnChange}
                values={iframeSrc?.length > 0 ? iframeSrc : []}
              />
            </div>
          </div>
          <div className="d-flex flex-row">
            {iframeSrc?.length > 0 ? (
              <Link
                href={iframeSrc[0]?.link}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(iframeSrc[0]?.link, "_blank");
                }}
                className="pe-2"
              >
                <ImageSvgs name="active_redirect_btn" />
              </Link>
            ) : (
              <div className="pe-2">
                <ImageSvgs name="redirect_btn" />
              </div>
            )}

            <div
              className="maximize-btn-div"
              onClick={() => {
                setDashboardView(true);
                setActiveLink(iframeSrc[0]);
              }}
            >
              <ImageSvgs name="max_icon" />
            </div>
          </div>
        </div>
        <div className="grid-container">
          {iframeSrc?.link !== null && links?.length > 0 ? (
            <Row xs={12} style={{ paddingTop: "0.5rem" }}>
              <iframe
                src={iframeSrc?.[0]?.link}
                name="sac"
                height="380px"
                width="100%"
                title="SAC Dashboard"
              ></iframe>
            </Row>
          ) : (
            <div className="no-dashboard">
              <ImageSvgs name="default_dashboard" />
              Click on “Manage Dashboards” to add new dashboards that can be
              seen here
            </div>
          )}
        </div>
      </div>

      {showDashboardModal === true ? (
        <DashboardEditModal
          show={showDashboardModal}
          onHide={onHide}
          links={links}
          setLinks={setLinks}
          setIframeSrc={setIframeSrc}
          setActiveLink={setActiveLink}
          // setUpdatelinks={setUpdatelinks}
        />
      ) : null}
      {dashboardView === true ? (
        <DashboardExpandModal
          show={dashboardView}
          onHide={() => {
            setDashboardView(false);
            setActiveLink(activeLink?.id);
          }}
          activeLink={activeLink}
        />
      ) : null}
    </div>
  );
}
