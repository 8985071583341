import "../ImageCarousel/ImageCarousel.scss";
import React, { useState, useEffect, useContext } from "react";
import { Carousel, Button } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContext";
import { getBaseUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { useNavigate } from "react-router";

const ImageCarousel = ({ props }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const baseUrl = getBaseUrl();
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    (async () => {
      const url = `${getBaseUrl()}${props["source-url"]}`;
      const resp = await getData(url, "idToken");

      setBannerData(resp.data);
    })();
    // eslint-disable-next-line
  }, []);

  if (bannerData) {
    return (
      <div className="banner-carousel">
        <Carousel controls={false} wrap={true} interval={4000}>
          {bannerData?.cards.map((record, i) => {
            return (
              <Carousel.Item key={i}>
                {record.link && record.link !== "" ? (
                  <a href={record.link} target="_blank" rel="noreferrer">
                    <img
                      className="banner-img"
                      src={baseUrl.concat(record["img-url"])}
                      alt="Alt"
                    />
                    {i === 0 ? (
                      <Carousel.Caption className="fade-in-text d-flex flex-column text-dark">
                        <h4 className="fw-bold">Hi {user.givenName},</h4>
                        <h4 className="fw-bold">
                          Welcome to the GS Finance Center!
                        </h4>
                        <Button
                          className="app-secondary-btn w-25"
                          onClick={() => {
                            navigate("/landscape");
                          }}
                        >
                          Explore FIN GS
                        </Button>
                      </Carousel.Caption>
                    ) : (
                      ""
                    )}
                  </a>
                ) : (
                  <>
                    <img
                      className="banner-img"
                      src={baseUrl.concat(record["img-url"])}
                      alt="Alt"
                    />
                    {i === 0 ? (
                      <Carousel.Caption className="fade-in-text d-flex flex-column text-dark">
                        <h4 className="fw-bold">Hi {user.givenName},</h4>
                        <h4 className="fw-bold">
                          Welcome to the GS Finance Center!
                        </h4>
                        <Button
                          className="app-secondary-btn w-25"
                          onClick={() => {
                            navigate("/landscape");
                          }}
                        >
                          Explore FIN GS
                        </Button>
                      </Carousel.Caption>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    );
  }
};

export default ImageCarousel;
