import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { getData } from "../../services/apiService";
import { getBaseUrl } from "../../config/environment";
import ImageSvgs from "../ImageSvgs";
import { Form, Button } from "react-bootstrap";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  ReactFlowProvider,
} from "reactflow";
import { getHostUrl } from "../../config/environment";
import "./AppStyles.scss";

let id = 1;
const getId = () => `${id++}`;

const Flow = ({ setLevel }) => {
  const [appDetails, setAppDetails] = useState(
    JSON.parse(sessionStorage.getItem("appDetails"))
  );

  // eslint-disable-next-line
  const [appConfig, setAppConfig] = useState({});

  const [key, setKey] = useState(null);
  const [toolsList, setToolsList] = useState([]);
  const [templist, setTempList] = useState([]);
  const [layerId, setLayerId] = useState(null);
  const [toolDetails, setToolDetails] = useState(null);
  const [desc, setDesc] = useState("");
  const [layerList, setLayerList] = useState();

  const [flag, setFlag] = useState("selectTool");

  const yPos = useRef(0);
  const uiPos = useRef(-140);
  const intPos = useRef(-140);
  const souPos = useRef(-140);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const addNode = useCallback((props) => {
    props.layer.id === 1
      ? (uiPos.current += 160)
      : props.layer.id === 2
      ? (intPos.current += 160)
      : (souPos.current += 160);

    yPos.current += 160;
    const id = getId();
    const newNode = {
      id,
      position: { x: 0, y: 0 },
      targetPosition: "left",
      sourcePosition: "right",
      data: {
        toolDetails: props.tool,
        layerId: props.layer,
        description: props.desc,
        label: (
          <div className="node-box">
            <div
              className="node-up"
              style={{
                backgroundColor:
                  props.layer.id === 1
                    ? "#8C7500"
                    : props.layer.id === 2
                    ? "#006C3B"
                    : "#00328C",
                borderRadius: "3px",
              }}
            >
              <div className="layerText">{props.layer.name}</div>
              <div className="nameText" title="name">
                {props.tool.attributes["tool-name"]}
              </div>
            </div>
            <div className="node-down">
              <div className="descText" title="desc">
                {props.desc}
              </div>
            </div>
          </div>
        ),
      },
    };
    setNodes((nds) => nds.concat(newNode));

    // eslint-disable-next-line
  }, []);

  const addEdge = useCallback(({ source, target }) => {
    setEdges((eds) =>
      eds.concat({
        id: Math.random(),
        type: "step",
        source,
        target,
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleSearchFilter = (value) => {
    const newList = templist?.filter((item) =>
      item.attributes["tool-name"].toLowerCase().includes(value.toLowerCase())
    );
    setToolsList(newList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    (async () => {
      const tools = await getData(
        `${getHostUrl()}tools?include=apps,trainings,experts,communities&use_pagination=false`
      );
      await setTempList(tools.data.data);
      await setToolsList(tools.data.data);
      const layerConfig = JSON.parse(sessionStorage.getItem("appConfig"));
      if (layerConfig) {
        layerConfig?.nodes.forEach((elem) => {
          const newNode = {
            id: elem.id,
            position: elem.position,
            targetPosition: "left",
            sourcePosition: "right",
            data: {
              label: (
                <div className="node-box">
                  <div
                    className="node-up"
                    style={{
                      backgroundColor:
                        elem.data.layerId.id === 1
                          ? "#8C7500"
                          : elem.data.layerId.id === 2
                          ? "#006C3B"
                          : "#00328C",
                      borderRadius: "3px",
                    }}
                  >
                    <div className="layerText">{elem.data.layerId.name}</div>
                    <div className="nameText" title="name">
                      {elem.data.toolDetails.attributes["tool-name"]}
                    </div>
                  </div>
                  <div className="node-down">
                    <div className="descText" title="desc">
                      {elem.data.description}
                    </div>
                  </div>
                </div>
              ),
            },
          };
          setNodes((nds) => nds.concat(newNode));
        });

        setEdges(layerConfig.edges);
      }
      const res = await getData(
        `${getBaseUrl()}/configurations/layerLookup.json`
      );
      await setLayerList(res.data.layers);
      await setAppDetails(JSON.parse(sessionStorage.getItem("appDetails")));
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main-app">
      {toolsList ? (
        <>
          <div className="app-title d-flex flex-row justify-content-between mb-3">
            <span>
              <h4 className="fw-bold">{appDetails?.app_title}</h4>
              <h6>{appDetails?.app_description}</h6>
            </span>
            <span>
              <ImageSvgs name="progress_2" />
            </span>
          </div>

          <div className="layers-section d-flex w-100">
            <div className="list-section me-3 w-25">
              {flag === "selectTool" ? (
                <div>
                  <div className="w-100">
                    <span className="select-tool mx-auto">
                      <p className="fw-bold mt-3 mb-2">Select Tool</p>
                    </span>
                    <div className="app-search-box mx-auto">
                      <form
                        className="search-app"
                        onSubmit={(e) => handleSubmit(e)}
                      >
                        <div className="app-primary-input-field w-100">
                          <input
                            type="search"
                            placeholder="Search tools name"
                            onChange={(e) => {
                              handleSearchFilter(e.target.value);
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="tools-list mb-3 mt-2">
                    {toolsList?.map((tool) => {
                      return (
                        <div className="list-comp border bg-white mx-auto mt-2 p-2">
                          <span className="d-flex justify-content-between">
                            <span>
                              <b>Id: </b>
                              {tool.id}
                            </span>
                            <span className="me-1">
                              <ImageSvgs name="appListIcon" />
                            </span>
                          </span>
                          <p className="mb-0">{tool.attributes["tool-name"]}</p>
                          <div className="d-flex w-100 justify-content-end">
                            <button
                              className="border-0 bg-transparent"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add Tool"
                              onClick={() => {
                                setToolDetails(tool);
                                setDesc(tool.attributes["tool-description"]);
                                setFlag("selectLayer");
                              }}
                            >
                              <ImageSvgs name="addTool" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="select-layer">
                  <span className="select-tool mx-auto">
                    <p className="fw-bold mt-3 mb-2">Select Layer</p>
                  </span>
                  <div className="buttons-section mx-auto">
                    <button
                      className={
                        key === 1
                          ? `layer-btn btn-1 act-btn-1 px-2 py-1 me-2 mb-2`
                          : `layer-btn btn-1 px-2 py-1 me-2 mb-2`
                      }
                      onClick={() => {
                        setKey(1);
                        setLayerId(
                          layerList?.filter((item) => item.id === 1)[0]
                        );
                      }}
                    >
                      UI Layer
                    </button>
                    <button
                      className={
                        key === 2
                          ? `layer-btn btn-2 act-btn-2 px-2 py-1 me-2 mb-2`
                          : `layer-btn btn-2 px-2 py-1 me-2 mb-2`
                      }
                      onClick={() => {
                        setKey(2);
                        setLayerId(
                          layerList?.filter((item) => item.id === 2)[0]
                        );
                      }}
                    >
                      Integration Layer
                    </button>
                    <button
                      className={
                        key === 3
                          ? `layer-btn btn-3 act-btn-3 px-2 py-1 me-2 mb-2`
                          : `layer-btn btn-3 px-2 py-1 me-2 mb-2`
                      }
                      onClick={() => {
                        setKey(3);
                        setLayerId(
                          layerList?.filter((item) => item.id === 3)[0]
                        );
                      }}
                    >
                      Source Layer
                    </button>
                  </div>
                  <div className="mt-2 tool-desc mx-auto">
                    <Form className="border-0 p-0">
                      <Form.Group
                        className="mb-3 w-100"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          <b>Description:</b>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter a short description"
                          value={desc}
                          name="tool_description"
                          rows={4}
                          onChange={(e) => {
                            setDesc(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  {toolDetails ? (
                    <div className="tool-details mx-auto d-flex flex-column mb-3">
                      <b>Tool details:-</b>
                      <span>
                        <b>Name: </b>
                        {toolDetails?.attributes["tool-name"]}
                      </span>
                      <span>
                        <b>Layer: </b>
                        {layerId?.name}
                      </span>
                    </div>
                  ) : null}
                  <div className="d-flex w-100 justify-content-end add-tool-btn mx-auto mb-3">
                    <Button
                      className="app-primary-btn"
                      // disabled={layerId ? true : false}
                      onClick={async () => {
                        addNode({
                          tool: toolDetails,
                          layer: layerId,
                          desc: desc,
                        });
                        setFlag("selectTool");
                        setDesc("");
                        setLayerId(null);
                        setKey(null);
                      }}
                    >
                      Add Tool
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="react-flow-section w-75">
              <div className="flow-canvas">
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onConnect={addEdge}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  deleteKeyCode="Delete"
                  draggable={false}
                  nodesDraggable={true}
                >
                  <Controls />
                </ReactFlow>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end w-100 mt-3">
            <Button
              className="app-secondary-btn my-auto me-2"
              onClick={() => {
                setLevel("addApp");
              }}
            >
              Cancel
            </Button>
            <Button
              className="app-primary-btn"
              onClick={async () => {
                if (nodes.length > 0 && edges.length > 0) {
                  let newElem = {
                    nodes: nodes,
                    edges: edges,
                  };
                  sessionStorage.setItem("appConfig", JSON.stringify(newElem));
                  await setAppConfig(newElem);
                  setLevel("addPreview");
                } else {
                  alert("Connections missing!");
                }
              }}
            >
              Next Step
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

function AddLayers(props) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
}

export default AddLayers;
