import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { getAuthConfig, getHostUrl } from "../config/environment";
import { getData } from "./apiService";

export function getMSALInstance() {
  const config = getAuthConfig();
  const pca = new PublicClientApplication({
    auth: {
      clientId: config.clientId,
      authority: config.authority,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: config.cacheLocation,
      storeAuthStateInCookie: config.storeAuthStateInCookie,
    },
    system: {
      loggerOptions: config.useLogger
        ? {
            loggerCallback: (level, message, containsPII) => {
              console.log(message);
            },
            logLevel: "Verbose",
          }
        : {},
    },
  });

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      pca.setActiveAccount(event.payload.account);
    }
  });

  return pca;
}
export const configureNewTokens = async (instance) => {
  const token = await instance.acquireTokenSilent({
    account: instance.getActiveAccount(),
    forceRefresh: true,
    scopes: ["https://api.yammer.com/user_impersonation"],
  });
  sessionStorage.setItem("accessToken", token.accessToken);
  sessionStorage.setItem("idToken", token.idToken);
  let tempDate = new Date();
  sessionStorage.setItem(
    "tokenExpiry",
    new Date(tempDate.setMinutes(tempDate.getMinutes() + 46))
  );
  sessionStorage.setItem(
    "expiryDuration",
    Math.trunc(
      (new Date(sessionStorage.getItem("tokenExpiry")) - new Date()) / 1000
    )
  );
  sessionStorage.setItem("loginTime", new Date().getTime());
  alert("Your session has been refreshed!");
};

export const configureTokens = async (instance) => {
  sessionStorage.clear();
  const token = await instance.acquireTokenSilent({
    account: instance.getActiveAccount(),
    scopes: ["https://api.yammer.com/user_impersonation"],
  });

  sessionStorage.setItem("accessToken", token.accessToken);
  sessionStorage.setItem("idToken", token.idToken);
  let tempDate = new Date();
  sessionStorage.setItem(
    "tokenExpiry",
    new Date(tempDate.setMinutes(tempDate.getMinutes() + 46))
  );
  sessionStorage.setItem(
    "expiryDuration",
    Math.trunc(
      (new Date(sessionStorage.getItem("tokenExpiry")) - new Date()) / 1000
    )
  );
  sessionStorage.setItem("loginTime", new Date().getTime());
  sessionStorage.setItem("msgLoaded", "false");
  sessionStorage.setItem(
    "chatHistory",
    JSON.stringify({
      data: [
        {
          id: "1",
          sender: "chatGPT",
          message: "Welcome to the **GS Finance Center!** How may I help you?",
          links: [],
          reference: null,
        },
      ],
    })
  );
  sessionStorage.setItem(
    "feedbackList",
    JSON.stringify({
      data: [],
    })
  );
  const sharePointAccessToken = await getData(
    `${getHostUrl()}sharepoint/token`
  );
  sessionStorage.setItem(
    "sharePointAccessToken",
    sharePointAccessToken.data["access_token"]
  );
  sessionStorage.setItem("allTokensLoaded", true);
};
