import React from "react";
import { useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { UserModal } from "../../utils/toolsUtils";
import { deleteData } from "../../services/apiService";
import { getHostUrl, getBaseUrl } from "../../config/environment";
import Loader from "../Loader/Loader";

const AppsTable = ({ data, included, setLevel, setIsDeleted }) => {
  const navigate = useNavigate();

  const initialData = data;
  const [tableData, setTableData] = useState(data);
  const [modalShow, setModalShow] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [appData, setAppData] = useState();
  const [userData, setUserData] = useState();
  const [nameQuery, setNameQuery] = useState("");
  const [descQuery, setDescQuery] = useState("");

  const setUsers = (list) => {
    let userList = [];
    // eslint-disable-next-line
    list.map((e) => {
      let newElem = included?.filter(
        (item) =>
          item.type === "owners" && item.id.toString() === e.id.toString()
      )[0];
      userList.push(newElem);
    });
    return userList;
  };

  const nameSearch = (query) => {
    if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0"
    ) {
      setTableData(initialData);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["app-title"].toLowerCase().includes(query)
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["app-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() > "0" &&
      descQuery?.length.toString() > "0"
    ) {
      setTableData(
        initialData?.filter(
          (item) =>
            item.attributes["app-title"].toLowerCase().includes(query) &&
            item.attributes["app-description"]
              .toLowerCase()
              .includes(descQuery.toLowerCase())
        )
      );
    }
  };

  const descSearch = (query) => {
    if (
      nameQuery?.length.toString() === "0" &&
      query.length.toString() === "0"
    ) {
      setTableData(initialData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query.length.toString() === "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["app-title"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query.length.toString() !== "0"
    ) {
      setTableData(
        initialData?.filter((item) =>
          item.attributes["app-description"].toLowerCase().includes(query)
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      query.length.toString() > "0"
    ) {
      setTableData(
        initialData?.filter(
          (item) =>
            item.attributes["app-title"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["app-description"].toLowerCase().includes(query)
        )
      );
    }
  };

  const DeleteAppModal = (props) => {
    const [loading, setLoading] = useState(false);

    const deleteApp = async (id) => {
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      };
      try {
        setLoading(true);
        // eslint-disable-next-line
        const res = await deleteData(
          `${getHostUrl()}application/${id}`,
          headers
        );
        setLoading(false);
        setIsDeleted(true);
        props.onHide();
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Application
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Name: {props.appdata.attributes["app-title"]}</h5>
          <p>
            Are you sure you want to delete this application? All the
            connections of this application within FIN GS would be lost.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="common-cancel-btn me-2" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            id="common-favbutton"
            onClick={() => {
              deleteApp(props.appdata.id);
            }}
          >
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {tableData ? (
        <Table className="main-table" striped>
          <thead className="user-table-head">
            <tr className="border-bottom">
              <th>Application Name</th>
              <th>Application Description</th>
              <th style={{ textAlign: "left" }}>Application Owners</th>
              <th className="text-center">Actions</th>
            </tr>
            <tr style={{ height: "3.5rem" }}>
              <th>
                {" "}
                <div className="app-primary-input-field mb-2">
                  <input
                    className="me-3 w-100"
                    style={{
                      height: "32px !important",
                      width: "95% !important",
                    }}
                    type="search"
                    placeholder=" Search by name..."
                    value={nameQuery}
                    onChange={(e) => {
                      setNameQuery(e.target.value);
                      nameSearch(e.target.value.toLowerCase());
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field mb-2">
                  <input
                    className="search-bar me-3 w-100"
                    style={{ height: "32px !important" }}
                    type="search"
                    placeholder=" Search by description..."
                    value={descQuery}
                    onChange={(e) => {
                      setDescQuery(e.target.value);
                      descSearch(e.target.value.toLowerCase());
                    }}
                  />
                </div>
              </th>
              <th style={{ textAlign: "left" }}>
                <div className="app-primary-input-field mb-2">
                  <input
                    disabled
                    className="me-3 w-100"
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field mb-2">
                  <input
                    disabled
                    className="me-3 w-100"
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData && tableData?.length > 0 ? (
              <>
                {tableData
                  ?.sort((a, b) =>
                    a.attributes["app-title"].localeCompare(
                      b.attributes["app-title"]
                    )
                  )
                  .map((item) => (
                    <tr key={item.id} className="table-striped-bg">
                      <td className="my-auto">
                        <span
                          className="me-2 app-table-name"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Visit Application"
                        >
                          <Link
                            className="app-table-name"
                            style={{ textDecoration: "none" }}
                            to={`/landscape/applications?orgId=${item.attributes["app-org"].appOrg[0].id}&appId=${item.id}`}
                            state={{ prevPath: window.location.href }}
                          >
                            {item.attributes["app-title"]}
                          </Link>
                        </span>
                      </td>
                      <td className="d-flex my-auto">
                        <span className="text-overflow">
                          {item.attributes["app-description"]}
                        </span>
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <span className="ms-3">
                          {item?.relationships?.owners?.data.length > 0 ? (
                            <>
                              {item?.relationships?.owners?.data
                                .slice(0, 3)
                                .map((elem, index) => {
                                  let owner = included?.filter(
                                    (item) =>
                                      item.type === "owners" &&
                                      item.id.toString() === elem.id.toString()
                                  )[0];

                                  return (
                                    <span
                                      className="me-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate(
                                          `/employee_relation?id=${owner.attributes["employee-id"]}`
                                        )
                                      }
                                    >
                                      {owner?.attributes?.employee?.data
                                        .attributes["profile-picture"] ? (
                                        <img
                                          className="my-auto"
                                          src={`${getBaseUrl()}${
                                            owner?.attributes?.employee?.data
                                              .attributes["profile-picture"]
                                          }`}
                                          style={{
                                            height: "2rem",
                                            width: "2rem",
                                            border: "1.5px solid black",
                                            borderRadius: "7rem",
                                            objectFit: "contain",
                                          }}
                                          alt="User Img"
                                        />
                                      ) : (
                                        <Avatar
                                          name={
                                            owner?.attributes?.employee?.data
                                              ?.attributes["preferred-name"]
                                          }
                                          size="32"
                                          round={true}
                                        />
                                      )}
                                    </span>
                                  );
                                })}
                              {item?.relationships?.owners?.data.length > 3 ? (
                                <>
                                  <button
                                    className="more-user-btn"
                                    onClick={async () => {
                                      let userList = await setUsers(
                                        item.relationships?.owners?.data
                                      );

                                      setUserData(userList);
                                      setShowUserModal(true);
                                    }}
                                  >
                                    +{" "}
                                    {item.relationships?.owners?.data.length -
                                      3}{" "}
                                    more
                                  </button>
                                </>
                              ) : null}
                            </>
                          ) : (
                            <b>-</b>
                          )}
                        </span>
                      </td>
                      <td className="my-auto">
                        <span className="d-flex justify-content-center">
                          <span
                            className="me-3 d-flex"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Application"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setLevel("editApp");
                              sessionStorage.setItem(
                                "editData",
                                JSON.stringify(item)
                              );
                            }}
                          >
                            <ImageSvgs name="edit" />
                          </span>

                          <span
                            className="me-2 d-flex"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete Application"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModalShow(true);
                              setAppData(item);
                            }}
                          >
                            <ImageSvgs name="delete" />
                          </span>
                        </span>
                      </td>
                    </tr>
                  ))}
              </>
            ) : (
              <tr className="w-100 mt-2">
                <td
                  className="w-100"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#e9ecef ",
                  }}
                >
                  <p className="fw-bold mt-1 mb-0">
                    No matching records found.
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        <div style={{ maxWidth: "100%", height: "35rem" }}>
          <Loader />
        </div>
      )}
      {modalShow ? (
        <DeleteAppModal
          appdata={appData}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      ) : null}
      {showUserModal === true ? (
        <UserModal
          title={"Application Owners:-"}
          listData={userData}
          show={showUserModal}
          onHide={() => setShowUserModal(false)}
        />
      ) : null}
    </>
  );
};

export default AppsTable;
