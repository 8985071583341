import React, { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { getData } from "../../services/apiService";
import { getBaseUrl } from "../../config/environment";
import "react-date-picker/dist/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "./UltimoComp.scss";
import { AiOutlineCalendar } from "react-icons/ai";
import { Button } from "react-bootstrap";

const calendarIcon = (
  <span className="fw-bold" style={{ fontSize: "20px" }}>
    <AiOutlineCalendar />
  </span>
);

const CalendarComp = () => {
  const [ultimoData, setUltimoData] = useState(null);
  const [loading, setLoading] = useState(false);

  const currUltimoData = ultimoData?.filter(
    (elem) => elem.date === new Date().toISOString().slice(0, 10)
  )[0];

  useEffect(() => {
    (async () => {
      const ultimoRes = await getData(`${getBaseUrl()}/ultimo/ultimo.json`);
      setUltimoData(ultimoRes.data);
    })();
  }, []);

  const renderDayContents = (day, date) => {
    let currDate = new Date(date);
    let nextDate = new Date(currDate);
    nextDate.setDate(currDate.getDate() + 1);
    let currData = ultimoData?.filter(
      (elem) => elem.date === nextDate.toISOString().slice(0, 10)
    )[0];

    return (
      <div className="d-flex flex-column mt-1">
        <p className="mb-0">{day}</p>
        <p className="mb-0" style={{ color: "#8a00e5", fontSize: "14px" }}>
          {currData?.ultimo_pos ? currData.ultimo_pos : null}
        </p>
        <p className="mb-0" style={{ color: "#8a00e5", fontSize: "14px" }}>
          {currData?.ultimo_neg ? currData?.ultimo_neg : null}
        </p>
      </div>
    );
  };

  const downloadICSFile = async () => {
    try {
      setLoading(true);
      const response = await getData(`${getBaseUrl()}/ultimo/ultimo.ics`);
      const blob = new Blob([response.data], { type: "text/calendar" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "UltimoDates.ics");
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="d-flex">
      {ultimoData ? (
        <div className="d-flex flex-column">
          <div
            className="d-flex mx-auto my-auto fw-bold"
            style={{ fontSize: "18px" }}
          >
            <p className="mb-0 mt-1 me-2">{`${new Date().getDate()} ${new Date().toLocaleString(
              "default",
              {
                month: "long",
              }
            )} ${new Date().getFullYear()}`}</p>
            <span style={{ cursor: "pointer" }}>
              <DatePicker
                showPopperArrow={false}
                selected={new Date()}
                showTimeSelect={false}
                dateFormat="Pp"
                shouldCloseOnSelect={false}
                minDate={new Date("2023-10-01")}
                maxDate={new Date("2024-09-30")}
                customInput={calendarIcon}
                renderDayContents={renderDayContents}
              >
                <div className="w-100 d-flex">
                  <Button
                    className="mx-auto app-secondary-btn mb-2"
                    disabled={loading}
                    onClick={() => {
                      downloadICSFile();
                    }}
                  >
                    {loading ? "Dowloading Ultimo..." : "Download Ultimo"}
                  </Button>
                </div>
              </DatePicker>
            </span>
          </div>

          <div
            className="d-flex fw-bold"
            style={{ color: "#8a00e5", fontSize: "14px" }}
          >
            <div className="me-2">
              {currUltimoData?.ultimo_pos !== null &&
              currUltimoData?.ultimo_pos !== " "
                ? currUltimoData.ultimo_pos
                : "- "}
              {currUltimoData?.ultimo_pos &&
              currUltimoData?.ultimo_pos?.toLowerCase().includes("ultimo")
                ? ""
                : currUltimoData?.ultimo_neg !== null &&
                  currUltimoData?.ultimo_neg !== " "
                ? `/${currUltimoData?.ultimo_neg}`
                : "/ -"}
            </div>
            <div className="me-1">
              {currUltimoData?.period ? currUltimoData?.period : ""}
            </div>
            <div>
              {currUltimoData?.fyear
                ? `FY${currUltimoData?.fyear.slice(-2)}`
                : ""}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CalendarComp;
